
    import './styles.scoped.css';
    export default {
  "error-icon-shake-wrapper": "awsui_error-icon-shake-wrapper_14mhv_96w9j_149",
  "warning-icon-shake-wrapper": "awsui_warning-icon-shake-wrapper_14mhv_96w9j_150",
  "awsui-motion-shake-horizontally": "awsui_awsui-motion-shake-horizontally_14mhv_96w9j_1",
  "error-icon-scale-wrapper": "awsui_error-icon-scale-wrapper_14mhv_96w9j_180",
  "warning-icon-scale-wrapper": "awsui_warning-icon-scale-wrapper_14mhv_96w9j_181",
  "awsui-motion-scale-popup": "awsui_awsui-motion-scale-popup_14mhv_96w9j_1",
  "warning": "awsui_warning_14mhv_96w9j_150",
  "error": "awsui_error_14mhv_96w9j_149",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_14mhv_96w9j_1",
  "root": "awsui_root_14mhv_96w9j_232",
  "label": "awsui_label_14mhv_96w9j_268",
  "info": "awsui_info_14mhv_96w9j_282",
  "description": "awsui_description_14mhv_96w9j_288",
  "constraint": "awsui_constraint_14mhv_96w9j_289",
  "hints": "awsui_hints_14mhv_96w9j_296",
  "constraint-has-validation-text": "awsui_constraint-has-validation-text_14mhv_96w9j_297",
  "secondary-control": "awsui_secondary-control_14mhv_96w9j_301",
  "controls": "awsui_controls_14mhv_96w9j_305",
  "label-hidden": "awsui_label-hidden_14mhv_96w9j_305",
  "label-wrapper": "awsui_label-wrapper_14mhv_96w9j_308",
  "control": "awsui_control_14mhv_96w9j_305",
  "error__message": "awsui_error__message_14mhv_96w9j_335",
  "warning__message": "awsui_warning__message_14mhv_96w9j_336",
  "visually-hidden": "awsui_visually-hidden_14mhv_96w9j_340"
};
  