import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DeleteModal from '@/components/DeleteModal';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import TabHeader from '@/components/TabHeader';
import {
  useDeleteUserGroupsMutation,
  useGetUserGroupsWithApproversQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { evictField } from '@/utils/graphqlUtils';

import AddGroupModal from './AddGroupModal';
import type { GroupsTableFields } from './config';
import { useGetCollectionTableProps } from './config';

const GroupsTab: FC = () => {
  useI18NSummaryHelpContent('userGroups.help');
  const { t } = useTranslation(['common'], { keyPrefix: 'userGroups' });
  const { t: st } = useTranslation(['common']);
  const { addNotification } = useNotifications();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedUserGroups, setSelectedUserGroups] = useState<
    GroupsTableFields[]
  >([]);

  const { data, loading, refetch } = useGetUserGroupsWithApproversQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const tableProps = useGetCollectionTableProps(data?.user_group ?? []);
  tableProps.isItemDisabled = (item) => (item?.ApprovalCount ?? 0) > 0;

  const [deleteUserGroups, deleteResult] = useDeleteUserGroupsMutation({
    update: (cache) => {
      evictField(cache, 'user_group');
    },
  });

  const handleAddGroupModalOpen = () => {
    setIsAddModalOpen(true);
  };

  const handleAddGroupModalClose = () => {
    setIsAddModalOpen(false);
    refetch();
  };

  const onDelete = useDeleteResultNotification({
    entityName: t('entity_name'),
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
    asyncAction: async () => {
      await deleteUserGroups({
        variables: {
          UserGroupIds: selectedUserGroups.map((s) => s.Id),
        },
      });
      setSelectedUserGroups([]);
      setIsDeleteModalVisible(false);
      refetch();

      return true;
    },
  });

  return (
    <>
      <Table
        {...tableProps}
        header={
          <SpaceBetween size="m">
            <TabHeader
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    formAction="none"
                    variant="normal"
                    disabled={!selectedUserGroups.length}
                    onClick={() => setIsDeleteModalVisible(true)}
                  >
                    {t('delete')}
                  </Button>
                  <Button
                    variant="primary"
                    formAction="none"
                    onClick={handleAddGroupModalOpen}
                  >
                    {t('add_button')}
                  </Button>
                  <Button iconName="download" onClick={tableProps.exportToCsv}>
                    {st('export')}
                  </Button>
                </SpaceBetween>
              }
            >
              {t('groupsTableTitle')}
            </TabHeader>
          </SpaceBetween>
        }
        variant="embedded"
        loading={loading}
        selectionType="multi"
        selectedItems={selectedUserGroups}
        onSelectionChange={({ detail }) => {
          setSelectedUserGroups(detail.selectedItems);
        }}
        trackBy="Id"
      />
      {isAddModalOpen && <AddGroupModal onDismiss={handleAddGroupModalClose} />}
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={t('delete')}
        onDelete={onDelete}
        onDismiss={() => {
          refetch();
          setSelectedUserGroups([]);
          setIsDeleteModalVisible(false);
        }}
      >
        {t('confirm_delete_message')}
      </DeleteModal>
    </>
  );
};

export default GroupsTab;
