import _ from 'lodash';
import type { ReactNode } from 'react';

import { FormField } from '@/components/Form/Form/FormField';

import { TextInput, type TextInputProps } from './TextInput';

export interface TextInputWithFormFieldProps extends TextInputProps {
  label: string;
  info?: ReactNode;
  stretch?: boolean;
  testId?: string;
}

export const TextInputWithFormField = ({
  label,
  stretch,
  errorMessage,
  testId,
  info,
  ...props
}: TextInputWithFormFieldProps) => {
  return (
    <FormField
      label={label}
      errorText={errorMessage}
      stretch={stretch}
      testId={testId}
      info={info}
    >
      <TextInput {...props} />
    </FormField>
  );
};
