import type { FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router';
import { namedOperations, useInsertRiskMutation } from 'src/generated/graphql';
import type { RiskFormDataFields } from 'src/pages/risks/forms/riskSchema';

import { ownerAndContributorIds } from '@/components/Form';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import { evictField } from '@/utils/graphqlUtils';
import { riskDetailsUrl } from '@/utils/urls';

import RiskForm from '../../forms/RiskForm';

const RiskCreateTab: FC = () => {
  useI18NSummaryHelpContent('risks.help');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tierParam = searchParams.get('tier');

  const [mutate] = useInsertRiskMutation({
    update: (cache) => {
      evictField(cache, 'risk');
      evictField(cache, 'risk_aggregate');
      evictField(cache, 'risk_score');
    },
    refetchQueries: [
      namedOperations.Query.getRiskById,
      namedOperations.Query.getRisksByTier,
    ],
  });

  const onSave = async (riskFormData: RiskFormDataFields) => {
    const { data } = await mutate({
      variables: {
        object: {
          DepartmentTypeIds: riskFormData.DepartmentTypeIds,
          TagTypeIds: riskFormData.TagTypeIds,
          Tier: riskFormData.Tier,
          Title: riskFormData.Title,
          Description: riskFormData.Description,
          Treatment: riskFormData.Treatment,
          Status: riskFormData.Status,
          CustomAttributeData: riskFormData.CustomAttributeData ?? null,
          ParentRiskId: riskFormData.ParentRiskId ?? null,
          schedule: riskFormData.schedule,
          ...ownerAndContributorIds(riskFormData),
        },
      },
    });
    if (data?.insertChildRisk?.Id) {
      navigate(riskDetailsUrl(data?.insertChildRisk?.Id), { replace: true });
    }
  };

  const onDismiss = (saved?: boolean) => {
    if (!saved) {
      navigate(-1);
    }
  };

  return (
    <RiskForm
      onSave={onSave}
      onDismiss={onDismiss}
      initialTier={tierParam ? Number(tierParam) : undefined}
    />
  );
};

export default RiskCreateTab;
