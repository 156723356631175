import Modal from '@cloudscape-design/components/modal';
import SpaceBetween from '@cloudscape-design/components/space-between';
import TextContent from '@cloudscape-design/components/text-content';
import { JsonForms } from '@jsonforms/react';
import type { ErrorObject } from 'ajv';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../Button';
import DeleteButton from '../DeleteButton';
import DeleteModal from '../DeleteModal';
import type {
  CustomUISchema,
  CustomUISchemaElement,
} from './context/useFormBuilderContext';
import { useFormBuilderContext } from './context/useFormBuilderContext';
import { FormBuilderAction } from './context/useFormBuilderContext';
import type { SectionData } from './form-configs/section';
import { sectionSchema, sectionUISchema } from './form-configs/section';
import rendererRegistry from './renderers/registry';

type EditFormSectionModalProps = {
  action: FormBuilderAction;
  sectionData: SectionData;
  setSectionData: (sectionData: SectionData) => void;
  isEditingSection: boolean;
  setIsEditingSection: (isEditingSection: boolean) => void;
  initialSectionData: SectionData;
  uiSchema: CustomUISchema | CustomUISchemaElement;
};

export const FormSectionModal: FC<EditFormSectionModalProps> = ({
  action,
  sectionData,
  setSectionData,
  isEditingSection,
  setIsEditingSection,
  initialSectionData,
  uiSchema,
}) => {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [errors, setErrors] = useState<ErrorObject[] | undefined>([]);
  const { t } = useTranslation(['common'], {
    keyPrefix: 'formBuilder.formSection',
  });

  const {
    isFormCustomisable,
    setIsFormDirty,
    addNewSection,
    updateSection,
    deleteSection,
  } = useFormBuilderContext();

  const resetModal = () => {
    setSectionData(initialSectionData);
    setIsEditingSection(false);
    setIsFormDirty(false);
  };

  const handleEditSectionCancel = () => {
    resetModal();
  };

  const handleAddNewSection = () => {
    addNewSection(sectionData);
    resetModal();
  };

  const handleUpdateSection = () => {
    updateSection(sectionData, uiSchema);
    resetModal();
  };

  const handleSaveSection = () => {
    if (errors?.length) {
      setIsFormDirty(true);

      return;
    }

    if (action === FormBuilderAction.Add) {
      handleAddNewSection();
    }

    if (action === FormBuilderAction.Edit) {
      handleUpdateSection();
    }
  };

  const handleDeleteSection = () => {
    deleteSection(uiSchema);
    resetModal();
  };

  return (
    <>
      {isEditingSection ? (
        <>
          <Modal
            onDismiss={resetModal}
            visible={isEditingSection}
            data-testid={'form-section-modal'}
            header={
              action === FormBuilderAction.Add
                ? t('addSectionButtonLabel')
                : t('editSectionButtonLabel')
            }
            footer={
              <div className={'flex justify-between'}>
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="primary"
                    onClick={handleSaveSection}
                    formAction={'submit'}
                  >
                    {t('saveButtonLabel')}
                  </Button>
                  <Button variant="normal" onClick={handleEditSectionCancel}>
                    {t('cancelButtonLabel')}
                  </Button>
                </SpaceBetween>
                {action === FormBuilderAction.Edit ? (
                  <DeleteButton onClick={() => setIsDeleteModalVisible(true)}>
                    {t('deleteButtonLabel')}
                  </DeleteButton>
                ) : null}
              </div>
            }
          >
            {/*
              Avoid spreading data into Json Forms as it is a
              known anti-pattern that causes infinite render loops
              https://jsonforms.io/faq/#how-can-i-minimize-re-rendering
            */}
            <JsonForms
              data={sectionData}
              readonly={!isFormCustomisable}
              schema={sectionSchema}
              uischema={sectionUISchema}
              renderers={rendererRegistry}
              onChange={({ data, errors }) => {
                const formattedErrors = errors?.map((error) => ({
                  ...error,
                  dataPath: '',
                })) as ErrorObject[] | undefined;

                setErrors(formattedErrors);
                setSectionData(data);
              }}
              validationMode={'ValidateAndShow'}
            />
          </Modal>
          <DeleteModal
            loading={false}
            isVisible={isDeleteModalVisible}
            onDelete={handleDeleteSection}
            onDismiss={() => setIsDeleteModalVisible(false)}
            header={t('deleteModal.header')}
          >
            <TextContent>
              <p className={'whitespace-pre-wrap'}>{t('deleteModal.body')}</p>
            </TextContent>
          </DeleteModal>
        </>
      ) : null}
    </>
  );
};
