import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHasPermission } from 'src/rbac/useHasPermission';

import { CustomisableFormContext } from '@/components/Form/Form/CustomisableFormContext';
import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import type { FormContextProps } from '@/components/Form/Form/types';
import { Parent_Type_Enum } from '@/generated/graphql';

import ObligationDetailsFormFields from './ObligationDetailsFormFields';
import type { ObligationFormFieldData } from './obligationSchema';
import { ObligationSchema } from './obligationSchema';

export type Props = Omit<
  FormContextProps<ObligationFormFieldData>,
  'schema' | 'formId' | 'parentType' | 'renderTemplate' | 'i18n' | 'header'
> & {
  obligationId?: string;
  parentObligationNode?: {
    Id: string;
    SequentialId?: number | null | undefined;
    ObjectType: Parent_Type_Enum;
  } | null;
  latestTestDate?: string | null;
  initialType?: ObligationFormFieldData['Type'];
};

const ObligationDetailsForm: FC<Props> = ({ defaultValues, ...props }) => {
  const { t } = useTranslation(['common']);
  const { user } = useRisksmartUser();
  const canInsertStandardObligations = useHasPermission('insert:obligation');
  const defaultType =
    props.initialType ||
    (canInsertStandardObligations || props.obligationId
      ? 'standard'
      : 'chapter');
  const defaultData: ObligationFormFieldData = {
    ...(defaultValues as ObligationFormFieldData),
    Type: defaultType,
    Owners: [
      {
        type: 'user',
        value: user!.userId!,
      },
    ],
  };

  return (
    <CustomisableFormContext
      {...props}
      defaultValues={defaultData}
      formId={'obligation-form'}
      i18n={t('obligations')}
      header={t('details')}
      schema={ObligationSchema}
      parentType={Parent_Type_Enum.Obligation}
      renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
    >
      <ObligationDetailsFormFields
        readOnly={props.readOnly}
        obligationId={props.obligationId}
        parentObligationNode={props.parentObligationNode}
        latestTestDate={props.latestTestDate}
      />
    </CustomisableFormContext>
  );
};

export default ObligationDetailsForm;
