import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import type { FC, ReactNode } from 'react';

import {
  Appetite_Model_Enum,
  Risk_Scoring_Model_Enum,
  useGetAggregationSettingsForOrgQuery,
} from '@/generated/graphql';

import { AggregationContext } from './AggregationContext';

export const AggregationProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { isAuthenticated } = useRisksmartUser();

  const { data, loading } = useGetAggregationSettingsForOrgQuery({
    skip: !isAuthenticated,
  });

  return (
    <AggregationContext.Provider
      value={{
        loading,
        name: 'default',
        riskScoringModel:
          data?.aggregation_org?.[0]?.RiskScoringModel ??
          Risk_Scoring_Model_Enum.Default,
        appetiteAggregation:
          data?.aggregation_org?.[0]?.Appetite ?? Appetite_Model_Enum.Default,
        enableTierTwoCascading:
          data?.aggregation_org?.[0]?.Config?.enableTierTwoCascading ?? false,
      }}
    >
      {children}
    </AggregationContext.Provider>
  );
};
