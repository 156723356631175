type CsvFieldType = number | string | boolean | null | undefined;

export const downloadBlob = (fileName: string, blob: Blob) => {
  const anchor = document.createElement('a');
  document.body.appendChild(anchor);

  const objectUrl = window.URL.createObjectURL(blob);
  anchor.href = objectUrl;
  anchor.download = fileName;
  anchor.click();
  window.URL.revokeObjectURL(objectUrl);
};

export function arrayToCsv(data: CsvFieldType[][]) {
  return data
    .map((row) =>
      row
        .map((value) =>
          typeof value === 'string' ? value.replaceAll('"', '""') : value
        )

        .map((value) => (typeof value === 'string' ? `"${value}"` : value))
        .map((value) => {
          if (value === undefined || value === null) {
            return '';
          }

          return String(value);
        })
        .join(',')
    )
    .join('\r\n');
}

export const humanFileSize = (bytes: number, dp = 2) => {
  const thresh = 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + ' ' + units[u];
};
