import type { FC } from 'react';
import { useNavigate } from 'react-router';
import { useHasPermission } from 'src/rbac/useHasPermission';

import { ownerAndContributorIds } from '@/components/Form';
import { useInsertDocumentMutation } from '@/generated/graphql';
import { policyDetailsUrl } from '@/utils/urls';

import DocumentForm from '../../forms/DocumentForm';
import type { DocumentFormFieldData } from '../../forms/documentSchema';

const Tab: FC = () => {
  const canCreateDocument = useHasPermission('insert:document');
  const navigate = useNavigate();
  const [mutate] = useInsertDocumentMutation({});

  const onSave = async ({
    ancestorContributors: _1,
    Contributors,
    Owners,
    linkedDocuments,
    attestationTimeLimit,
    attestationPromptText,
    attestationGroups,
    requireAttestationFromEveryone,
    departments: _2,
    tags: _3,
    ...data
  }: DocumentFormFieldData) => {
    const result = await mutate({
      variables: {
        object: {
          ...data,
          ...ownerAndContributorIds({ Contributors, Owners }),
          LinkedDocumentIds: linkedDocuments.map(
            (linkedDocument) => linkedDocument.value
          ),
          CustomAttributeData: data.CustomAttributeData || undefined,
          RequireGlobalAttestation: requireAttestationFromEveryone === 'true',
          AttestationTimeLimit: attestationTimeLimit,
          AttestationPromptText: attestationPromptText,
          AttestationGroupIds: attestationGroups.map((group) => group.value),
        },
      },
    });
    if (result.data?.insertChildDocument?.Id) {
      navigate(policyDetailsUrl(result.data?.insertChildDocument?.Id), {
        replace: true,
      });
    }
  };

  const onDismiss = (saved: boolean) => {
    if (!saved) {
      navigate(-1);
    }
  };

  return (
    <DocumentForm
      onDismiss={onDismiss}
      onSave={onSave}
      readOnly={!canCreateDocument}
    />
  );
};

export default Tab;
