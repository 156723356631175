import { useFileUpdate } from '@risksmart-app/components/File/useFileUpdate';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import IndicatorsDetailsForm from 'src/pages/indicators/forms/IndicatorDetailsForm';
import type { IndicatorFormDataFields } from 'src/pages/indicators/forms/indicatorSchema';
import { defaultValues } from 'src/pages/indicators/forms/indicatorSchema';
import { getContributors, getOwners } from 'src/rbac/contributorHelper';
import { useHasPermission } from 'src/rbac/useHasPermission';

import { ownerAndContributorIds } from '@/components/Form';
import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import type { GetIndicatorByIdQuery } from '@/generated/graphql';
import {
  Parent_Type_Enum,
  useUpdateIndicatorMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

type Props = {
  indicator: GetIndicatorByIdQuery['indicator'][0];
};

const Tab: FC<Props> = ({ indicator }) => {
  useI18NSummaryHelpContent('indicators.detailsHelp');
  const { updateFiles } = useFileUpdate();
  const canUpdateIndicator = useHasPermission('update:indicator', indicator);
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();
  const [updateIndicator] = useUpdateIndicatorMutation({
    update: (cache) => evictField(cache, 'indicator'),
  });
  const onSave = async (data: IndicatorFormDataFields) => {
    if (!indicator) {
      throw new Error('Missing indicator data');
    }
    const { files, newFiles, ...rest } = data;
    await updateIndicator({
      variables: {
        object: {
          Id: indicator.Id,
          CustomAttributeData: data.CustomAttributeData || undefined,
          DepartmentTypeIds: rest.DepartmentTypeIds,
          Description: rest.Description,
          LowerAppetiteNum:
            rest.Type == 'number' ? rest.LowerAppetiteNum : undefined,
          LowerToleranceNum:
            rest.Type == 'number' ? rest.LowerToleranceNum : undefined,
          TagTypeIds: rest.TagTypeIds,
          TargetValueTxt:
            rest.Type === 'text' ? rest.TargetValueTxt : undefined,

          Title: rest.Title,
          Type: rest.Type,
          Unit: rest.Unit,
          UpperAppetiteNum:
            rest.Type == 'number' ? rest.UpperAppetiteNum : undefined,
          UpperToleranceNum:
            rest.Type == 'number' ? rest.UpperToleranceNum : undefined,
          schedule: rest.schedule,
          ...ownerAndContributorIds(data),
        },
      },
    });

    await updateFiles({
      parentType: Parent_Type_Enum.Indicator,
      parentId: indicator?.Id,
      newFiles: newFiles,
      originalFiles: indicator?.files,
      selectedFiles: files,
    });
  };

  const onDismiss = () => navigate(-1);
  const indicatorValue: IndicatorFormDataFields = {
    ...defaultValues,
    ...indicator,
    Owners: getOwners(indicator),
    Contributors: getContributors(indicator),
    ancestorContributors: indicator?.ancestorContributors ?? [],
  } as IndicatorFormDataFields;

  return (
    <IndicatorsDetailsForm
      values={indicatorValue}
      onSave={onSave}
      onDismiss={onDismiss}
      readOnly={!canUpdateIndicator}
      header={t('details')}
      isUpdate={true}
      latestTestDate={indicator.scheduleState?.LatestDate || undefined}
      renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
    />
  );
};

export default Tab;
