import type { Auth0ContextInterface, User } from '@auth0/auth0-react';
import axios from 'axios';
import type { Context, FC, ReactNode } from 'react';
import { useEffect } from 'react';

import AxiosContext from '../contexts/axiosContext';
import useRisksmartUser from '../hooks/useRisksmartUser';
import { getEnv } from '../utils/environment';
import { handleError } from '../utils/errorUtils';

const instance = axios.create({
  baseURL: getEnv('REACT_APP_REST_API_URL'),
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    handleError(error);

    return Promise.reject(error);
  }
);

type Props = {
  authContext?: Context<Auth0ContextInterface<User>>;
  children: ReactNode;
};

export const AxiosProvider: FC<Props> = ({ children, authContext }) => {
  const { getAccessTokenSilently } = useRisksmartUser(authContext);
  useEffect(() => {
    instance.interceptors.request.use(async function (config) {
      const token = await getAccessTokenSilently();
      config.headers['Authorization'] = `Bearer ${token}`;

      return config;
    });
  }, [getAccessTokenSilently]);

  return (
    <AxiosContext.Provider value={instance}>{children}</AxiosContext.Provider>
  );
};
