import { useCollection } from '@cloudscape-design/collection-hooks';
import Grid from '@cloudscape-design/components/grid';
import type { SelectProps } from '@cloudscape-design/components/select';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import { defaultPropertyFilterI18nStrings } from '@risksmart-app/components/Table/propertyFilterI18nStrings';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';
import { Permission } from 'src/rbac/Permission';

import Select from '@/components/Form/Select';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import PageFilterContainer from '@/components/PageFilterContainer/PageFilterContainer';
import PropertyFilterPanel from '@/components/PropertyFilterPanel';
import {
  useGetAppetitesGroupedByImpactQuery,
  useGetRisksFlatQuery,
} from '@/generated/graphql';
import { useRiskScores } from '@/hooks/useRiskScore';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { useAddCustomAttributes } from '@/utils/table/hooks/useAddCustomAttributes';
import { useCreateFilterOptions } from '@/utils/table/hooks/useCreateFilterOptions';
import { useCreateFilterProperties } from '@/utils/table/hooks/useCreateFilterProperties';
import { addRiskUrl } from '@/utils/urls';

import { useGetFieldConfig } from '../risks/config';
import { useGetLabelledFields } from '../risks/useGetLabelledFields';
import styles from './style.module.scss';
import Tier from './Tier';
import type { DashboardState } from './types';
import { RiskAttribute } from './types';

const initialDashboardState = new Map<number, string | undefined>(
  Array.from({ length: 3 }, (_, i) => [i + 1, undefined])
);

const Page: FC = () => {
  useI18NSummaryHelpContent('risks.dashboardHelp');
  const { t } = useTranslation(['common'], {
    keyPrefix: 'risks',
  });
  const title = t('dashboard_title');

  const [dashboardState, setDashboardState] = useState<DashboardState>(
    initialDashboardState
  );
  const fields = useGetFieldConfig();
  const { addNotification } = useNotifications();
  const { data } = useGetRisksFlatQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
    onCompleted: (data) => {
      // Select the first tier 1 risk
      const firstTier1Risk = data.risk.find((r) => r.Tier === 1);
      if (firstTier1Risk) {
        initialDashboardState.set(1, firstTier1Risk?.Id);
        setDashboardState(initialDashboardState);
      }
    },
  });

  const { scores } = useRiskScores();
  const { data: impactAppetites } = useGetAppetitesGroupedByImpactQuery();
  const labelledFields = useGetLabelledFields(
    data?.risk,
    scores,
    impactAppetites?.impact
  );
  const { tableFields, tableData } = useAddCustomAttributes({
    fields,
    data: labelledFields,
    customAttributeSchema:
      data?.form_configuration?.[0]?.customAttributeSchema ?? null,
    useRelativeDates: true,
  });
  const filteringProperties = useCreateFilterProperties(tableFields);

  const { items, propertyFilterProps } = useCollection(tableData, {
    propertyFiltering: {
      filteringProperties,
    },
    selection: {},
  });
  const isImpactsEnabled = useIsFeatureVisibleToOrg('impacts');
  const fixedFilterOptions = useCreateFilterOptions(
    tableFields,
    tableData,
    propertyFilterProps.filteringOptions
  );

  // TODO: should we removed controlled rating, uncontrolled rating when impacts enabled?
  const options: SelectProps.Option[] = [
    {
      label: t('columns.controlled_rating'),
      value: RiskAttribute.ControlledRating,
    },
    {
      label: t('columns.uncontrolled_rating'),
      value: RiskAttribute.UncontrolledRating,
    },

    {
      label: t('columns.appetite_performance'),
      value: RiskAttribute.AppetitePerformance,
    },
    ...(isImpactsEnabled
      ? [
          {
            label: t('columns.impact_performance'),
            value: RiskAttribute.ImpactPerformance,
          },
        ]
      : []),
    {
      label: t('columns.risk_status'),
      value: RiskAttribute.RiskStatus,
    },
  ];
  const [selectedOption, setSelectOption] = useState<SelectProps.Option>(
    options[0]
  );

  return (
    <PageLayout
      actions={
        <Permission permission="insert:risk" canHaveAccessAsContributor={true}>
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="primary" href={addRiskUrl()}>
              {t('create_new_button')}
            </Button>
          </SpaceBetween>
        </Permission>
      }
      title={title}
    >
      <PageFilterContainer>
        <div className="flex w-full space-x-2">
          <div className="grow">
            <PropertyFilterPanel
              {...propertyFilterProps}
              filteringOptions={fixedFilterOptions}
              i18nStrings={defaultPropertyFilterI18nStrings}
            />
          </div>
          <div>
            <Select
              selectedOption={selectedOption}
              onChange={(e) => setSelectOption(e.detail.selectedOption)}
              options={options}
            />
          </div>
        </div>
      </PageFilterContainer>
      <div className={styles.dashboard}>
        <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
          <Tier
            tier={1}
            selectedRiskAttribute={selectedOption.value as RiskAttribute}
            tierRisks={items}
            dashboardState={dashboardState}
            setDashboardState={setDashboardState}
          />
          <Tier
            tier={2}
            selectedRiskAttribute={selectedOption.value as RiskAttribute}
            tierRisks={items}
            dashboardState={dashboardState}
            setDashboardState={setDashboardState}
          />
          <Tier
            tier={3}
            selectedRiskAttribute={selectedOption.value as RiskAttribute}
            tierRisks={items}
            dashboardState={dashboardState}
            setDashboardState={setDashboardState}
          />
        </Grid>
      </div>
    </PageLayout>
  );
};

export default Page;
