import { PageNotFound } from '@risksmart-app/components/errors/errors';
import { useFileUpdate } from '@risksmart-app/components/File/useFileUpdate';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { getContributors, getOwners } from 'src/rbac/contributorHelper';
import { useHasPermission } from 'src/rbac/useHasPermission';

import { contributorUpdateFields, ownerUpdateFields } from '@/components/Form';
import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import {
  Parent_Type_Enum,
  useGetActionByIdQuery,
  useUpdateActionMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import ActionForm from '../../forms/ActionForm';
import type { ActionFormFieldData } from '../../forms/actionsSchema';
import { defaultValues } from '../../forms/actionsSchema';

const Tab: FC = () => {
  useI18NSummaryHelpContent('actions.help');
  const { updateFiles } = useFileUpdate();
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();
  const actionId = useGetGuidParam('actionId');
  const { data, error } = useGetActionByIdQuery({
    variables: {
      _eq: actionId,
    },
    fetchPolicy: 'no-cache',
  });
  if (error) {
    throw error;
  }

  if (data?.action.length === 0) {
    throw new PageNotFound(`Action with id ${actionId} not found`);
  }
  const action = data?.action[0];
  const canEditAction = useHasPermission('update:action', action);
  const values = {
    ...defaultValues,
    ...action,
    Owners: getOwners(action),
    Contributors: getContributors(action),
    ancestorContributors: action?.ancestorContributors ?? [],
  };
  const [updateAction] = useUpdateActionMutation({
    update: (cache) => {
      evictField(cache, 'action');
      evictField(cache, 'action_aggregate');
      evictField(cache, 'control');
    },
  });

  const onDismiss = () => {
    navigate(-1);
  };

  const onSave = async (
    data: ActionFormFieldData,
    confirmChangeRequest?: boolean
  ) => {
    const { newFiles, files } = data;
    if (!action) {
      throw new Error('action missing');
    }
    await updateAction({
      variables: {
        ...data,
        Priority: data.Priority,
        OriginalTimestamp: action.ModifiedAtTimestamp,
        Id: action.Id,
        CustomAttributeData: data.CustomAttributeData || undefined,
        ...contributorUpdateFields(data, action.Id),
        ...ownerUpdateFields(data, action.Id),
      },
      context: {
        headers: {
          'x-confirm-change-request': confirmChangeRequest ? 'true' : 'false',
        },
      },
    });
    await updateFiles({
      parentType: Parent_Type_Enum.Action,
      parentId: action?.Id,
      newFiles,
      originalFiles: action?.files,
      selectedFiles: files,
    });
  };

  return (
    <ActionForm
      values={values}
      onDismiss={onDismiss}
      onSave={onSave}
      readOnly={!canEditAction}
      header={t('details')}
      approvalConfig={{ object: { Id: actionId } }}
      renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
    />
  );
};

export default Tab;
