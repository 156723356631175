import DatePicker from '@cloudscape-design/components/date-picker';
import { withJsonFormsControlProps } from '@jsonforms/react';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import SimpleDateInput from '../../../Form/SimpleDateInput/SimpleDateInput';
import type { ExtendedControlProps } from '../../context/useFormBuilderContext';
import { CustomisableControl } from './CustomisableControl';
import styles from './style.module.scss';

const DateControlUnwrapped: FC<ExtendedControlProps> = ({
  schema,
  uischema,
  errors,
  handleChange,
  enabled,
  data,
  id,
  path,
  config,
}) => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'formBuilder.placeholders',
  });

  const appliedUiSchemaOptions = {
    ...config,
    ...uischema.options,
  };

  return (
    <CustomisableControl uischema={uischema} errors={errors} schema={schema}>
      <SimpleDateInput
        value={data}
        onChange={(val) => {
          handleChange(path, val);
        }}
        disabled={!enabled}
      />
      <DatePicker
        {...{ className: styles.dateInput }}
        value={dayjs(data || Date.now()).format('YYYY-MM-DD')}
        onChange={(event) => {
          handleChange(path, event.detail.value);
        }}
        name={id}
        disabled={!enabled}
        autoFocus={appliedUiSchemaOptions.focus}
        placeholder={appliedUiSchemaOptions?.placeholder || t('date')}
      />
      {schema.allowAttachments && <></>}
    </CustomisableControl>
  );
};

export const DateControl = withJsonFormsControlProps(
  // For more info on why this is ignored, see `Known Issues` in `@risksmart-app/docs/form-builder.md`
  // @ts-ignore
  DateControlUnwrapped
);
