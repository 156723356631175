export const supportedCsvFiles = [
  'users.csv',
  'risks.csv',
  'controls.csv',
  'acceptances.csv',
  'appetites.csv',
  'appetiteParents.csv',
  'issues.csv',
  'actions.csv',
  'causes.csv',
  'consequences.csv',
  'departments.csv',
  'departmentTypes.csv',
  'tags.csv',
  'tagTypes.csv',
  'issueUpdates.csv',
  'actionUpdates.csv',
  'testResults.csv',
  'actionParents.csv',
  'issueAssessments.csv',
  'controlGroups.csv',
  'owners.csv',
  'contributors.csv',
  'controlParents.csv',
  'obligations.csv',
  'obligationAssessmentResults.csv',
  'assessments.csv',
  'controlledRiskAssessmentResults.csv',
  'uncontrolledRiskAssessmentResults.csv',
  'userGroups.csv',
  'ownerGroups.csv',
  'contributorGroups.csv',
  'indicators.csv',
  'indicatorResults.csv',
  'indicatorParents.csv',
  'schedules.csv',
];
