import { withFormBuilderContextProvider } from '@risksmart-app/components/FormBuilder/context/FormBuilderContextProvider';
import type { CustomUISchema } from '@risksmart-app/components/FormBuilder/context/useFormBuilderContext';
import { useFormBuilderContext } from '@risksmart-app/components/FormBuilder/context/useFormBuilderContext';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FormContext } from '@/components/Form/Form/InnerFormContext';
import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import type { FormContextProps } from '@/components/Form/Form/types';
import {
  Questionnaire_Template_Version_Status_Enum,
  Version_Status_Enum,
} from '@/generated/graphql';

import QuestionnaireTemplateVersionFormFields from './QuestionnaireTemplateVersionFormFields';
import type { QuestionnaireTemplateVersionFormFieldData } from './questionnaireTemplateVersionSchema';
import { QuestionnaireTemplateVersionFormSchema } from './questionnaireTemplateVersionSchema';

export type Props = Omit<
  FormContextProps<QuestionnaireTemplateVersionFormFieldData>,
  | 'schema'
  | 'formId'
  | 'parentType'
  | 'i18n'
  | 'renderTemplate'
  | 'submitActions'
> & {
  readonly?: boolean;
  isCreatingNewEntity: boolean;
  savedStatus: Questionnaire_Template_Version_Status_Enum;
  parentId: string;
  disableStatus: boolean;
  onPublish?: (
    data: QuestionnaireTemplateVersionFormFieldData
  ) => Promise<void>;
};

const QuestionnaireTemplateVersionForm: FC<Props> = (props) => {
  const { t } = useTranslation(['common']);
  const { t: qt } = useTranslation(['common'], {
    keyPrefix: 'questionnaire_template_versions',
  });

  const { values } = props;
  const { schema, uiSchema, setSchema, setUISchema, setIsFormCustomisable } =
    useFormBuilderContext();

  const isDraft =
    props.savedStatus === Questionnaire_Template_Version_Status_Enum.Draft;
  const isPublished =
    props.savedStatus === Questionnaire_Template_Version_Status_Enum.Published;
  const allowStatusChange = !props.readOnly && props.isCreatingNewEntity;

  useEffect(() => {
    if (values?.Schema) {
      setSchema(values.Schema);
    }

    if (values?.UISchema) {
      setUISchema(values.UISchema as CustomUISchema);
    }

    setIsFormCustomisable(isDraft);

    /* Disabling the eslint rule here because updating this effect will cause the
    uischema to appear as if it hasn't been updated correctly on save */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsFormCustomisable]);

  const onSave = (data: QuestionnaireTemplateVersionFormFieldData) => {
    return props.onSave({
      ...data,
      Schema: schema,
      UISchema: uiSchema,
    });
  };

  return (
    <FormContext
      {...props}
      header={qt('details')}
      i18n={t('questionnaire_template_versions')}
      schema={QuestionnaireTemplateVersionFormSchema}
      formId={'questionnaire-template-version-form'}
      renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
      submitActions={[
        ...(isDraft
          ? [
              {
                label: qt('save_draft'),
                action: onSave,
              },
            ]
          : []),
        ...(allowStatusChange && isDraft && !!props.onPublish
          ? [
              {
                label: qt('status.publish_present'),
                action: (data: QuestionnaireTemplateVersionFormFieldData) => {
                  if (!props.onPublish) {
                    return Promise.resolve();
                  }

                  return props.onPublish({
                    ...data,
                    Schema: schema,
                    UISchema: uiSchema,
                  });
                },
              },
            ]
          : []),
        ...(allowStatusChange && isPublished
          ? [
              {
                label: qt('status.archive_present'),
                action: (data: QuestionnaireTemplateVersionFormFieldData) =>
                  props.onSave({
                    ...data,
                    Status: Version_Status_Enum.Archived,
                  }),
              },
            ]
          : []),
      ]}
    >
      <QuestionnaireTemplateVersionFormFields
        disableStatus={props.disableStatus}
        isCreatingNewEntity={props.isCreatingNewEntity}
        savedStatus={props.savedStatus}
        parentId={props.parentId}
      />
    </FormContext>
  );
};

const QuestionnaireTemplateVersionFormWrapped = withFormBuilderContextProvider(
  QuestionnaireTemplateVersionForm
);

export default QuestionnaireTemplateVersionFormWrapped;
