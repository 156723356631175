import Table from '@cloudscape-design/components/table';
import _ from 'lodash';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from 'src/layouts/PageLayout';

const Page: FC = () => {
  const { t } = useTranslation(['common'], { keyPrefix: 'reports' });

  return (
    <PageLayout title={t('reports_title')}>
      <Table
        loading={false}
        items={[]}
        columnDefinitions={[]}
        empty={'Empty'}
      />
    </PageLayout>
  );
};

export default Page;
