import type { CustomSchema, CustomUISchema } from './useFormBuilderContext';

export const initialFormData = {} as unknown;

export const initialFormSchema: CustomSchema = {
  type: 'object',
  properties: {},
  required: [],
};

export const initialFormUISchema: CustomUISchema = {
  type: 'VerticalLayout',
  elements: [],
};
