import i18n from '@risksmart-app/components/providers/i18n';
import type { ActionTableFields } from 'src/pages/actions/types';

import { Parent_Type_Enum } from '@/generated/graphql';
import { GetActionsDocument } from '@/generated/graphql.typed';
import { actionRegisterUrl } from '@/utils/urls';

import { useGetActionSmartWidgetTableProps } from '../../../actions/config';
import {
  dateRangeFilter,
  departmentsFilter,
  tagsFilter,
} from '../../Gigawidget/util/filterHelpers';
import { createDataSource } from '../createDataSource';
import {
  dashboardDateClickthroughFilter,
  dateClickthroughFilter,
  defaultClickthroughFilterWithUnrated,
  tagAndDepartmentCategoryGetters,
} from '../dataSourceHelpers';

export default createDataSource({
  hasAccess: () => true,
  parentTypes: [Parent_Type_Enum.Action],
  documentNode: GetActionsDocument,
  defaultVariables: () => ({ where: {} }),
  useTablePropsHook: (data, options) =>
    useGetActionSmartWidgetTableProps(
      data?.action,
      data?.form_configuration[0]?.customAttributeSchema ?? null,
      options
    ),
  entityNamePlural: 'action_other',
  entityNameSingular: 'action_one',
  fields: 'actions.fields',
  dashboardFilterConfig: {
    tagsFilter: (tags) => ({ where: { tags: tagsFilter(tags) } }),
    departmentsFilter: (departments) => ({
      where: { departments: departmentsFilter(departments) },
    }),
    dateFilter: (dateRange, precision) => ({
      where: { DateRaised: dateRangeFilter(dateRange, precision) },
    }),
    dateClickthroughFilter: dashboardDateClickthroughFilter('DateRaised'),
  },
  clickThroughUrl: (filters) => actionRegisterUrl(filters),
  categoryGetters: [
    ...tagAndDepartmentCategoryGetters<ActionTableFields>(),
    {
      id: 'status',
      name: () => i18n.t('actions.columns.status'),
      categoryGetter: (item) => ({
        key: item.Status,
        label: item.StatusLabelled,
      }),
      clickthroughFilter:
        defaultClickthroughFilterWithUnrated('StatusLabelled'),
      ratingColourKey: 'action_status',
    },
    {
      id: 'priority',
      name: () => i18n.t('actions.columns.priority'),
      categoryGetter: (item) => ({
        key: item.Priority,
        label: item.PriorityLabelled,
      }),
      clickthroughFilter:
        defaultClickthroughFilterWithUnrated('PriorityLabelled'),
      ratingColourKey: 'priority',
    },
    {
      id: 'owner',
      name: () => i18n.t('actions.columns.owner'),
      categoryGetter: (item) =>
        item.allOwners.map((owner) => ({ key: owner.id, label: owner.label })),
      clickthroughFilter: (category) => [
        {
          propertyKey: 'allOwners',
          operator: '=',
          value: category.key,
        },
      ],
    },
    {
      id: 'dateRaised',
      name: () => `${i18n.t('actions.columns.date_raised')} Date`,
      categoryGetter: (data) => new Date(data.DateRaised),
      date: true,
      dashboardDateFilterOverride: (dateRange, precision) => ({
        where: { DateRaised: dateRangeFilter(dateRange, precision) },
      }),
      clickthroughFilter: dateClickthroughFilter('DateRaised'),
    },
    {
      id: 'targetCloseDate',
      name: () => `${i18n.t('actions.columns.due_date')} Date`,
      categoryGetter: (data) => new Date(data.DateDue),
      date: true,
      dashboardDateFilterOverride: (dateRange, precision) => ({
        where: { DateDue: dateRangeFilter(dateRange, precision) },
      }),
      clickthroughFilter: dateClickthroughFilter('DateDue'),
    },
    {
      id: 'createdDate',
      name: () => i18n.t('columns.created_on'),
      categoryGetter: (data) => new Date(data.CreatedAtTimestamp),
      date: true,
      dashboardDateFilterOverride: (dateRange, precision) => ({
        where: { CreatedAtTimestamp: dateRangeFilter(dateRange, precision) },
      }),
      clickthroughFilter: dateClickthroughFilter('CreatedAtTimestamp'),
    },
  ],
});
