import type { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import Select from '@cloudscape-design/components/select';
import { withJsonFormsControlProps } from '@jsonforms/react';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ExtendedControlProps } from '../../context/useFormBuilderContext';
import { CustomisableControl } from './CustomisableControl';

const DropdownSelectControlUnwrapped: FC<ExtendedControlProps> = ({
  schema,
  uischema,
  errors,
  handleChange,
  enabled,
  data,
  path,
  config,
}) => {
  const modifiedSchemaOptions = schema.oneOf?.map((option) => ({
    value: option.const,
    label: option.title,
  }));

  const [selectedOption, setSelectedOption] = useState<OptionDefinition | null>(
    data
      ? (modifiedSchemaOptions?.find((option) => option.value === data) ?? null)
      : null
  );

  const { t } = useTranslation(['common'], {
    keyPrefix: 'formBuilder.placeholders',
  });

  const appliedUiSchemaOptions = {
    ...config,
    ...uischema.options,
  };

  return (
    <CustomisableControl uischema={uischema} errors={errors} schema={schema}>
      <Select
        {...{ className: 'grow' }}
        selectedOption={selectedOption}
        onChange={({ detail }) => {
          setSelectedOption(detail.selectedOption);
          handleChange(path, detail.selectedOption.value);
        }}
        options={
          schema?.oneOf?.map((option) => ({
            label: option.title,
            value: option.const,
          })) as OptionDefinition[]
        }
        disabled={!enabled}
        autoFocus={appliedUiSchemaOptions.focus}
        placeholder={appliedUiSchemaOptions?.placeholder || t('optionSelect')}
      />
      {schema.allowAttachments && <></>}
    </CustomisableControl>
  );
};

export const DropdownSelectControl = withJsonFormsControlProps(
  // For more info on why this is ignored, see `Known Issues` in `@risksmart-app/docs/form-builder.md`
  // @ts-ignore
  DropdownSelectControlUnwrapped
);
