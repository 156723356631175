import Alert from '@cloudscape-design/components/alert';
import Box from '@cloudscape-design/components/box';
import { humanFileSize } from '@risksmart-app/components/File/fileUtils';
import { useFileDownload } from '@risksmart-app/components/File/useFileDownload';
import dayjs from 'dayjs';
import type { FC } from 'react';
import type { FieldValues } from 'react-hook-form';

import type { Relation_File } from '@/generated/graphql';

import { Controller } from '../FieldController/Controller';
import { useIsFieldReadOnly } from '../Form/CustomisableForm/hooks/useIsFieldReadOnly';
import type { ControlledBaseProps } from '../types';
import styles from './style.module.scss';

interface Props<T extends FieldValues>
  extends Omit<ControlledBaseProps<T>, 'label'> {
  disabled?: boolean;
}

export const ControlledFileList = <T extends FieldValues>({
  name,
  control,
  disabled,
  forceRequired,
  defaultRequired,
  allowDefaultValue,
}: Props<T>) => {
  const readOnly = useIsFieldReadOnly(name);

  return (
    <div className={styles.fileList}>
      <Controller
        name={name}
        defaultRequired={defaultRequired}
        forceRequired={forceRequired}
        allowDefaultValue={allowDefaultValue}
        control={control}
        render={({ field: { value, onChange } }) => {
          const files = (value ?? []) as (Relation_File | File)[];
          const { error } = control.getFieldState(name);

          return (
            <>
              {files.map((f) => {
                const props: Omit<FileItemProps, 'onRemove'> =
                  f instanceof File
                    ? {
                        file: f,
                        fileName: f.name,
                        fileSize: f.size,
                        timestamp: dayjs(f.lastModified).toISOString(),
                      }
                    : {
                        fileId: f.file!.Id!,
                        fileName: f.file!.FileName!,
                        fileSize: f.file!.FileSize!,
                        timestamp: f.file!.CreatedAtTimestamp!,
                      };

                return (
                  <FileItem
                    disabled={disabled || readOnly}
                    error={Boolean(error)}
                    key={props.fileId || props.file?.name}
                    {...props}
                    onRemove={() => {
                      const newFiles = files.filter((ff) => ff != f);
                      onChange(newFiles);
                    }}
                  />
                );
              })}
            </>
          );
        }}
      />
    </div>
  );
};

type FileItemProps = {
  fileId?: string;
  file?: File;
  onRemove: () => void;
  fileName: string;
  fileSize: number;
  timestamp: string;
  error?: boolean;
  disabled?: boolean;
};

const FileItem: FC<FileItemProps> = ({
  fileId,
  onRemove,
  fileName,
  fileSize,
  file,
  error,
  disabled,
}) => {
  const downloadFile = useFileDownload();

  return (
    <Box margin={{ vertical: 'xs' }} key={fileId || file?.name}>
      <Alert
        type={error ? 'error' : 'success'}
        onDismiss={onRemove}
        dismissible={!disabled}
      >
        <div
          className={styles.fileDetailContainer}
          onClick={async () => {
            downloadFile({ fileId, fileName, file });
          }}
        >
          {fileName}
          <br />
          <Box variant="small">{humanFileSize(fileSize)}</Box>
        </div>
      </Alert>
    </Box>
  );
};
