import Button from '@risksmart-app/components/Button';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_COL_WIDTH } from 'src/App.config';
import { Permission } from 'src/rbac/Permission';
import type { RecursivePartial } from 'src/testing/stub';

import type { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import type { Obligation_Assessment_Result } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { useGetContributorsFieldConfig } from '@/utils/table/hooks/useGetContributorsFieldConfig';
import { useGetDepartmentFieldConfig } from '@/utils/table/hooks/useGetDepartmentFieldConfig';
import { useGetIssuesFieldConfig } from '@/utils/table/hooks/useGetIssuesFieldConfig';
import { useGetOwnersFieldConfig } from '@/utils/table/hooks/useGetOwnersFieldConfig';
import type {
  StatefulTableOptions,
  UseGetTablePropsOptions,
} from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetStatelessTableProps } from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { useGetTagFieldConfig } from '@/utils/table/hooks/useGetTagFieldConfig';
import type { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';
import { addObligationUrl, obligationDetailsUrl } from '@/utils/urls';

import type { ObligationFields, ObligationTableFields } from './types';
import { useGetLabelledFields } from './useLabelledFields';

export const useGetFieldConfig = (): TableFields<ObligationTableFields> => {
  const allOwners = useGetOwnersFieldConfig<ObligationTableFields>();
  const allContributors =
    useGetContributorsFieldConfig<ObligationTableFields>();
  const tagField = useGetTagFieldConfig<ObligationTableFields>();
  const departmentField = useGetDepartmentFieldConfig<ObligationTableFields>(
    (r) => r.departments
  );
  const issueField = useGetIssuesFieldConfig<ObligationTableFields>();
  const { getByValue: statusGetByValue } = useRating('assessment_status');
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'obligations.columns',
  });
  const { t } = useTranslation(['common'], {
    keyPrefix: 'columns',
  });
  const { t: tr } = useTranslation(['ratings']);

  return useMemo(
    () => ({
      SequentialIdLabel: { header: t('id'), sortingField: 'SequentialId' },
      Title: {
        header: st('Title'),
        cell: (item) => (
          <Link variant="secondary" href={obligationDetailsUrl(item.Id)}>
            {item.Title}
          </Link>
        ),
        isRowHeader: true,
      },
      ParentTitle: {
        header: st('ParentTitle'),
        cell: (item) => item.ParentTitle ?? '-',
      },
      TypeLabel: {
        header: st('Type'),
      },
      allOwners,
      allContributors,
      LatestAssessmentResultsLabelled: {
        header: st('Rating'),
        cell: (item) => {
          const result = item.LatestAssessmentResult;
          const [rating] = [
            ...tr('performance_result_unrated'),
            ...tr('performance_result'),
          ].filter((res) => res.value === result);

          return <SimpleRatingBadge rating={rating} />;
        },
      },
      LatestAssessmentStatus: {
        header: st('AssessmentStatus'),
        cell: (item) => {
          return (
            <SimpleRatingBadge
              rating={statusGetByValue(item.LatestAssessmentStatus)}
            />
          );
        },
      },
      LinkedControlCount: {
        header: st('Controls'),
      },
      BreachedIssues: issueField,
      tags: tagField,
      departments: departmentField,
      //------------------
      CreatedAtTimestamp: dateColumn(st('CreatedAt'), 'CreatedAtTimestamp'),
      ModifiedAtTimestamp: dateColumn(st('ModifiedAt'), 'ModifiedAtTimestamp'),
      Description: {
        header: st('Description'),
        cell: (item) => item.Description || '-',
        maxWidth: MAX_COL_WIDTH,
      },
      Id: {
        header: t('guid'),
      },
      CreatedBy: {
        header: st('CreatedBy'),
        cell: (item) => item.CreatedBy ?? '-',
      },
      ModifiedBy: {
        header: st('ModifiedBy'),
        cell: (item) => item.ModifiedBy ?? '-',
      },
      ParentId: {
        header: st('ParentId'),
        cell: (item) => item.ParentId || '-',
      },
      LatestRatingDate: dateColumn(
        st('latest_rating_date'),
        'LatestRatingDate'
      ),
      NextTestDate: dateColumn(st('next_test_date'), 'NextTestDate'),
      TestFrequency: {
        header: st('test_frequency'),
        cell: (item) => {
          return item.TestFrequency ?? '-';
        },
      },
    }),
    [
      allContributors,
      allOwners,
      departmentField,
      issueField,
      st,
      statusGetByValue,
      t,
      tagField,
      tr,
    ]
  );
};

const useGetObligationTableProps = (
  records: ObligationFields[] | undefined,
  latestAssessmentResults:
    | Array<RecursivePartial<Obligation_Assessment_Result> | undefined | null>
    | undefined,
  customAttributeSchema?: CustomAttributeFields | null
): UseGetTablePropsOptions<ObligationTableFields> => {
  const { t } = useTranslation(['common'], { keyPrefix: 'obligations' });
  const fields = useGetFieldConfig();
  const labelledFields = useGetLabelledFields(records, latestAssessmentResults);

  return useMemo(
    () => ({
      tableId: 'obligationRegister',
      data: labelledFields,
      customAttributeSchema: customAttributeSchema,
      entityLabel: t('entity_name'),
      emptyCollectionAction: (
        <Permission permission="insert:obligation">
          <Button href={addObligationUrl()}>{t('create_button')}</Button>
        </Permission>
      ),
      preferencesStorageKey: 'ObligationRegisterTable-PreferencesV1',
      enableFiltering: true,
      initialColumns: [
        'Title',
        'ParentTitle',
        'TypeLabel',
        'allOwners',
        'LatestAssessmentResultsLabelled',
        'LatestAssessmentStatus',
        'LinkedControlCount',
        'tags',
        'departments',
      ],
      fields,
    }),
    [customAttributeSchema, fields, labelledFields, t]
  );
};

export const useGetCollectionTableProps = (
  records: ObligationFields[] | undefined,
  latestAssessmentResults:
    | Array<RecursivePartial<Obligation_Assessment_Result> | undefined | null>
    | undefined,
  customAttributeSchema?: CustomAttributeFields | null
): TablePropsWithActions<ObligationTableFields> => {
  const props = useGetObligationTableProps(
    records,
    latestAssessmentResults,
    customAttributeSchema
  );

  return useGetTableProps(props);
};

export const useGetObligationSmartWidgetTableProps = (
  records: ObligationFields[] | undefined,
  latestAssessmentResults:
    | Array<RecursivePartial<Obligation_Assessment_Result> | undefined | null>
    | undefined,
  customAttributeSchema: CustomAttributeFields | null,
  statefulTableOptions: StatefulTableOptions<ObligationTableFields>
): TablePropsWithActions<ObligationTableFields> => {
  const props = useGetObligationTableProps(
    records,
    latestAssessmentResults,
    customAttributeSchema
  );

  return useGetStatelessTableProps<ObligationTableFields>({
    ...props,
    ...statefulTableOptions,
    enableFiltering: false,
  });
};
