import type { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import Multiselect from '@cloudscape-design/components/multiselect';
import { withJsonFormsControlProps } from '@jsonforms/react';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ExtendedControlProps } from '../../context/useFormBuilderContext';
import { CustomisableControl } from './CustomisableControl';

const DropdownMultiselectControlUnwrapped: FC<ExtendedControlProps> = ({
  schema,
  uischema,
  errors,
  handleChange,
  enabled,
  data,
  path,
  config,
}) => {
  const modifiedSchemaOptions: OptionDefinition[] =
    schema.oneOf?.map((option) => ({
      value: option.const,
      label: option.title,
    })) ?? [];

  const [selectedOptions, setSelectedOptions] = useState<OptionDefinition[]>(
    data ? modifiedSchemaOptions : []
  );

  const { t } = useTranslation(['common'], {
    keyPrefix: 'formBuilder.placeholders',
  });

  const appliedUiSchemaOptions = {
    ...config,
    ...uischema.options,
  };

  return (
    <CustomisableControl uischema={uischema} errors={errors} schema={schema}>
      <Multiselect
        {...{ className: 'grow' }}
        selectedOptions={selectedOptions}
        onChange={({ detail }) => {
          setSelectedOptions(detail.selectedOptions as OptionDefinition[]);
          handleChange(
            path,
            detail.selectedOptions.map((option) => option.value)
          );
        }}
        options={
          schema?.oneOf?.map((option) => ({
            label: option.title,
            value: option.const,
          })) as OptionDefinition[]
        }
        disabled={!enabled}
        autoFocus={appliedUiSchemaOptions.focus}
        placeholder={
          appliedUiSchemaOptions?.placeholder || t('optionMultiselect')
        }
      />
      {schema.allowAttachments && <></>}
    </CustomisableControl>
  );
};

export const DropdownMultiselectControl = withJsonFormsControlProps(
  // For more info on why this is ignored, see `Known Issues` in `@risksmart-app/docs/form-builder.md`
  // @ts-ignore
  DropdownMultiselectControlUnwrapped
);
