import dayjs from 'dayjs';

import { Attestation_Record_Status_Enum } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';

import type { AttestationFlatField, AttestationRegisterFields } from './types';

export const useLabelledFields = (
  records: AttestationFlatField[] | undefined
): AttestationRegisterFields[] => {
  const { getByValue } = useRating('attestation_record_status');

  return (
    records?.map((record) => {
      const attestationStatus = getCorrectedAttestationStatus(record);

      return {
        ...record,
        AttestationStatusLabel: getByValue(attestationStatus)?.label ?? '-',
        // TODO: translation
        ActiveLabel: record.Active ? 'Yes' : 'No',
        User: record.user?.FriendlyName ?? record.UserId,
        Document: `${record.node.documentFile?.parent?.Title} (${record.node.documentFile?.Version})`,
        AttestationStatus: attestationStatus,
      };
    }) ?? []
  );
};

/**
 * Return the attestation status with the expiry date taken into account
 * @param record
 * @returns
 */
export const getCorrectedAttestationStatus = (record: {
  AttestationStatus: Attestation_Record_Status_Enum;
  ExpiresAt?: string | null | undefined;
}): Attestation_Record_Status_Enum =>
  dayjs(record.ExpiresAt).isBefore() &&
  record.AttestationStatus !== Attestation_Record_Status_Enum.Attested &&
  record.AttestationStatus !== Attestation_Record_Status_Enum.NotRequired
    ? Attestation_Record_Status_Enum.Expired
    : record.AttestationStatus;
