import type {
  ControlProps,
  JsonSchema7,
  Labelable,
  UISchemaElement,
  VerticalLayout,
} from '@jsonforms/core';
import { createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { QuestionData } from '../form-configs/question';
import type { SectionData } from '../form-configs/section';

export enum FormBuilderAction {
  Add = 'add',
  Edit = 'edit',
}

export type FieldOption = {
  value: string;
  generatedId: string;
};

export enum FieldOptionType {
  Text = 'text',
  TextArea = 'textArea',
  Number = 'number',
  Radio = 'radio',
  Url = 'url',
  Date = 'date',
  Dropdown = 'dropdown',
  MultiSelect = 'multiselect',
}

export type FieldType =
  | 'text'
  | 'textArea'
  | 'number'
  | 'radio'
  | 'url'
  | 'date'
  | 'dropdown'
  | 'multiselect';

type UseFieldTypeOptionsReturnType = {
  [key in FieldType]: { value: FieldType; label: string };
};

export const useFieldTypeOptions = (): UseFieldTypeOptionsReturnType => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'formBuilder.fieldTypes',
  });

  // TODO: Remove this ts-ignore once all field types have been implemented
  // @ts-ignore
  return {
    text: { value: FieldOptionType.Text, label: t(FieldOptionType.Text) },
    textArea: {
      value: FieldOptionType.TextArea,
      label: t(FieldOptionType.TextArea),
    },
    number: { value: FieldOptionType.Number, label: t(FieldOptionType.Number) },
    url: { value: FieldOptionType.Url, label: t(FieldOptionType.Url) },
    date: { value: FieldOptionType.Date, label: t(FieldOptionType.Date) },
    dropdown: {
      value: FieldOptionType.Dropdown,
      label: t(FieldOptionType.Dropdown),
    },
    multiselect: {
      value: FieldOptionType.MultiSelect,
      label: t(FieldOptionType.MultiSelect),
    },

    // TODO: Implement these
    // radio: { value: 'radio', label: t('radio') },
  };
};

interface CustomSchemaProperties extends JsonSchema7 {
  parentId?: string;
}

export type CustomSchema = JsonSchema7 & {
  properties?: CustomSchemaProperties;
  isCustomisable?: boolean;
  isPropertyRequired?: boolean;
  allowAttachments?: boolean;
};

export type CustomUISchemaElement = UISchemaElement &
  Labelable & {
    id: string;
    parentId?: string;
    elements?: CustomUISchemaElement[];
  };

export type ExtendedControlProps = ControlProps & {
  schema: CustomSchema;
  uischema: CustomUISchemaElement;
};

export type CustomUISchema = VerticalLayout & {
  id?: string;
  elements: CustomUISchemaElement[];
};

type FormBuilderContextState = {
  formData: unknown;
  schema: CustomSchema;
  uiSchema: CustomUISchema;
  currentElementUISchema: CustomUISchema | CustomUISchemaElement;
  isFormCustomisable: boolean;
  isFormDirty: boolean;
  isCustomising: boolean;
  isEditingQuestion: boolean;
  formQuestionModalAction: FormBuilderAction | null;
  questionData: QuestionData;
  parentId: string;
  setFormData: (formData: unknown) => void;
  setSchema: (schema: CustomSchema) => void;
  setUISchema: (uiSchema: CustomUISchema) => void;
  setCurrentElementUISchema: (
    currentElementUISchema: CustomUISchema | CustomUISchemaElement
  ) => void;
  setIsFormCustomisable: (isFormCustomisable: boolean) => void;
  setIsFormDirty: (isFormDirty: boolean) => void;
  setIsCustomising: (isCustomising: boolean) => void;
  setIsEditingQuestion: (isEditingQuestion: boolean) => void;
  setFormQuestionModalAction: (action: FormBuilderAction | null) => void;
  setQuestionData: (questionData: QuestionData) => void;
  setParentId: (parentId: string) => void;
  addNewSection: (sectionData: SectionData) => void;
  updateSection: (
    sectionData: SectionData,
    currentElementUISchema: CustomUISchema | CustomUISchemaElement
  ) => void;
  deleteSection: (
    currentElementUISchema: CustomUISchema | CustomUISchemaElement
  ) => void;
  addNewQuestion: (questionData: QuestionData, parentId: string) => void;
  updateQuestion: (
    questionData: QuestionData,
    currentElementUISchema: CustomUISchema | CustomUISchemaElement,
    parentId: string
  ) => void;
  deleteQuestion: (
    currentElementUISchema: CustomUISchema | CustomUISchemaElement,
    parentId: string
  ) => void;
};

export const FormBuilderContext = createContext<FormBuilderContextState | null>(
  null
);

export const useFormBuilderContext = () => {
  const context = useContext(FormBuilderContext);

  if (!context) {
    throw new Error(
      'useSetFormBuilderContext must be used within a FormBuilderContextProvider'
    );
  }

  return context;
};
