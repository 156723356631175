
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_polq8_6ggi7_5",
  "file-option-thumbnail": "awsui_file-option-thumbnail_polq8_6ggi7_6",
  "file-option-name": "awsui_file-option-name_polq8_6ggi7_7",
  "file-option-size": "awsui_file-option-size_polq8_6ggi7_8",
  "file-option-last-modified": "awsui_file-option-last-modified_polq8_6ggi7_9",
  "ellipsis-active": "awsui_ellipsis-active_polq8_6ggi7_10"
};
  