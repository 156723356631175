import { useMultiParentFileUpdate } from '@risksmart-app/components/File/useFileUpdate';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import type { FC } from 'react';
import { useNavigate } from 'react-router';

import { contributorUpdateFields, ownerUpdateFields } from '@/components/Form';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import {
  Parent_Type_Enum,
  useGetThirdPartyByIdQuery,
  useUpdateThirdPartyMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import {
  getContributors,
  getOwners,
} from '../../../../../rbac/contributorHelper';
import { useHasPermission } from '../../../../../rbac/useHasPermission';
import { ThirdPartyForm } from '../../../forms/ThirdPartyForm';
import type { ThirdPartyFormData } from '../../../forms/thirdPartySchema';
import { defaultValues } from '../../../forms/thirdPartySchema';

const DetailsTab: FC = () => {
  useI18NSummaryHelpContent('third_party.help');

  const navigate = useNavigate();

  const thirdPartyId = useGetGuidParam('id');
  const { data } = useGetThirdPartyByIdQuery({
    variables: { Id: thirdPartyId },
    fetchPolicy: 'no-cache',
  });

  const thirdParty = data?.third_party;

  const canEdit = useHasPermission('update:third_party', thirdParty);

  const [mutate] = useUpdateThirdPartyMutation({
    update: (cache) => {
      evictField(cache, 'third_party');
      evictField(cache, 'third_party_by_pk');
    },
  });
  const { updateFiles } = useMultiParentFileUpdate();

  const onSave = async (formData: ThirdPartyFormData) => {
    if (!data?.third_party?.Id) {
      return;
    }
    const { newFiles, files } = formData;

    await mutate({
      variables: {
        Id: data.third_party.Id,
        object: {
          Title: formData.title,
          Description: formData.description,
          CompanyName: formData.companyName,
          CompaniesHouseNumber: formData.companiesHouseNumber,
          Address: formData.address,
          CityTown: formData.cityTown,
          Postcode: formData.postcode,
          Country: formData.country,
          PrimaryContactName: formData.primaryContactName,
          ContactName: formData.contactName,
          ContactEmail: formData.contactEmail,
          CompanyDomain: formData.companyDomain,
          Type: formData.type,
          Status: formData.status,
          Criticality: formData.criticality,
          CustomAttributeData: formData.CustomAttributeData,
        },
        ...contributorUpdateFields(formData, thirdPartyId),
        ...ownerUpdateFields(formData, thirdPartyId),
        tags: formData.tags.map((t) => ({ ...t, ParentId: thirdPartyId })),
        departments: formData.departments.map((d) => ({
          ...d,
          ParentId: thirdPartyId,
        })),
        TagTypeIds: formData.TagTypeIds,
        DepartmentTypeIds: formData.DepartmentTypeIds,
      },
    });
    await updateFiles({
      parentType: Parent_Type_Enum.ThirdParty,
      parentIds: [data.third_party.Id],
      newFiles,
      originalFiles: data?.third_party?.files ?? [],
      selectedFiles: files,
    });
  };

  const onDismiss = () => {
    navigate(-1);
  };

  if (!thirdParty) {
    return null;
  }

  return (
    <ThirdPartyForm
      readOnly={!canEdit}
      values={{
        ...defaultValues,
        title: thirdParty.Title,
        description: thirdParty.Description,
        companyName: thirdParty.CompanyName,
        companiesHouseNumber: thirdParty.CompaniesHouseNumber,
        address: thirdParty.Address,
        cityTown: thirdParty.CityTown,
        postcode: thirdParty.Postcode,
        country: thirdParty.Country,
        primaryContactName: thirdParty.PrimaryContactName,
        contactName: thirdParty.ContactName,
        contactEmail: thirdParty.ContactEmail,
        companyDomain: thirdParty.CompanyDomain,
        type: thirdParty.Type,
        status: thirdParty.Status,
        criticality: String(thirdParty.Criticality) as unknown as number,
        Owners: getOwners(thirdParty),
        Contributors: getContributors(thirdParty),
        ancestorContributors: thirdParty.ancestorContributors,
        tags: thirdParty.tags,
        departments: thirdParty.departments,
        CustomAttributeData: thirdParty.CustomAttributeData,
        TagTypeIds: [],
        DepartmentTypeIds: [],
        files: thirdParty.files,
        newFiles: [],
      }}
      onSave={onSave}
      onDismiss={onDismiss}
    />
  );
};

export default DetailsTab;
