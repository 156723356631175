
    import './styles.scoped.css';
    export default {
  "icon-shake": "awsui_icon-shake_1cbgc_m81e6_149",
  "awsui-motion-shake-horizontally": "awsui_awsui-motion-shake-horizontally_1cbgc_m81e6_1",
  "container-fade-in": "awsui_container-fade-in_1cbgc_m81e6_177",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_1cbgc_m81e6_1",
  "root": "awsui_root_1cbgc_m81e6_199",
  "status-error": "awsui_status-error_1cbgc_m81e6_208",
  "status-warning": "awsui_status-warning_1cbgc_m81e6_211",
  "status-success": "awsui_status-success_1cbgc_m81e6_214",
  "status-info": "awsui_status-info_1cbgc_m81e6_217",
  "status-stopped": "awsui_status-stopped_1cbgc_m81e6_220",
  "status-pending": "awsui_status-pending_1cbgc_m81e6_223",
  "status-in-progress": "awsui_status-in-progress_1cbgc_m81e6_226",
  "status-loading": "awsui_status-loading_1cbgc_m81e6_229",
  "color-override-red": "awsui_color-override-red_1cbgc_m81e6_232",
  "color-override-grey": "awsui_color-override-grey_1cbgc_m81e6_235",
  "color-override-blue": "awsui_color-override-blue_1cbgc_m81e6_238",
  "color-override-green": "awsui_color-override-green_1cbgc_m81e6_241",
  "color-override-yellow": "awsui_color-override-yellow_1cbgc_m81e6_244",
  "container": "awsui_container_1cbgc_m81e6_177",
  "display-inline": "awsui_display-inline_1cbgc_m81e6_248",
  "icon": "awsui_icon_1cbgc_m81e6_149",
  "display-inline-block": "awsui_display-inline-block_1cbgc_m81e6_256",
  "overflow-ellipsis": "awsui_overflow-ellipsis_1cbgc_m81e6_265"
};
  