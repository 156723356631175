import Button from '@risksmart-app/components/Button';
import { LinkExternal01 } from '@untitled-ui/icons-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from 'src/rbac/Permission';
import type { RecursivePartial } from 'src/testing/stub';

import type { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import Link from '@/components/Link';
import PolicyDocumentStatusBadge from '@/components/PolicyDocumentStatusBadge/PolicyDocumentStatusBadge';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import type { Document_Assessment_Result } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { EMPTY_CELL } from '@/utils/collectionUtils';
import { useGetContributorsFieldConfig } from '@/utils/table/hooks/useGetContributorsFieldConfig';
import { useGetDepartmentFieldConfig } from '@/utils/table/hooks/useGetDepartmentFieldConfig';
import { useGetOwnersFieldConfig } from '@/utils/table/hooks/useGetOwnersFieldConfig';
import type {
  StatefulTableOptions,
  UseGetTablePropsOptions,
} from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetStatelessTableProps } from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { useGetTagFieldConfig } from '@/utils/table/hooks/useGetTagFieldConfig';
import type { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';
import {
  addPolicyUrl,
  policyDetailsUrl,
  publicPolicyFileUrl,
} from '@/utils/urls';

import type { DocumentFields, PolicyRegisterFields } from './types';
import { useLabelledFields } from './useLabelledFields';

const useGetFieldConfig = (): TableFields<PolicyRegisterFields> => {
  const allOwners = useGetOwnersFieldConfig<PolicyRegisterFields>();
  const allContributors = useGetContributorsFieldConfig<PolicyRegisterFields>();
  const tagField = useGetTagFieldConfig<PolicyRegisterFields>();
  const departmentField = useGetDepartmentFieldConfig<PolicyRegisterFields>(
    (r) => r.departments
  );
  const { t } = useTranslation(['common'], { keyPrefix: 'columns' });
  const { getByValue: getPerformanceByValue } = useRating('performance_result');
  const { t: st } = useTranslation(['common'], { keyPrefix: 'policy.columns' });

  return useMemo(
    () => ({
      SequentialIdLabel: { header: t('id'), sortingField: 'SequentialId' },
      Title: {
        header: st('title'),
        cell: (item) => (
          <Link variant="secondary" href={policyDetailsUrl(item.Id)}>
            {item.Title}
          </Link>
        ),
        isRowHeader: true,
        sortingComparator: (a, b) =>
          a.Title.localeCompare(b.Title, undefined, { numeric: true }),
      },
      Parent: {
        header: st('parent'),
      },
      DocumentType: {
        header: st('type'),
      },
      allOwners,
      allContributors,
      PerformanceResult: {
        header: st('rating'),
        cell: (item) => (
          <SimpleRatingBadge
            rating={getPerformanceByValue(item.PerformanceResultValue)}
          />
        ),
      },
      Status: {
        header: st('status'),
        cell: (item) =>
          item.StatusValue ? (
            <PolicyDocumentStatusBadge
              item={{
                Status: item.StatusValue,
                NextReviewDate: item.NextReviewDate,
              }}
            />
          ) : (
            EMPTY_CELL
          ),
      },
      ReviewDate: dateColumn(
        st('review_date'),
        'ReviewDate',
        undefined,
        false,
        true
      ),
      NextReviewDate: dateColumn(
        st('review_due'),
        'NextReviewDate',
        undefined,
        false,
        true
      ),
      tags: tagField,
      departments: departmentField,
      Id: {
        header: t('guid'),
      },
      CreatedByUserName: {
        header: t('created_by_username'),
      },
      ModifiedByUserName: {
        header: t('updated_by_username'),
      },
      CreatedAtTimestamp: dateColumn(
        t('created_on'),
        'CreatedAtTimestamp',
        undefined,
        false,
        true
      ),
      ModifiedAtTimestamp: dateColumn(
        t('updated_on'),
        'ModifiedAtTimestamp',
        undefined,
        false,
        true
      ),
      CreatedByUserId: { header: t('created_by_id') },
      ModifiedByUserId: { header: t('updated_by_id') },
      Download: {
        header: st('download'),
        cell: (item) => {
          return item.Download ? (
            <Link href={publicPolicyFileUrl(item.Id)}>
              {st('download')}
              <LinkExternal01
                width={'15px'}
                height={'15px'}
                className={'ml-2'}
                viewBox={'0 0 24 24'}
              />
            </Link>
          ) : (
            EMPTY_CELL
          );
        },
      },
      LatestRatingDate: dateColumn(
        st('latest_rating_date'),
        'LatestRatingDate',
        undefined,
        false,
        true
      ),
      NextTestDate: dateColumn(
        st('next_test_date'),
        'NextTestDate',
        undefined,
        false,
        true
      ),
      NextTestOverdueDate: dateColumn(
        st('nextTestOverdue'),
        'NextTestOverdueDate'
      ),
      TestFrequency: {
        header: st('test_frequency'),
        cell: (item) => {
          return item.TestFrequency ?? '-';
        },
      },
      LastApprovedDate: dateColumn(
        st('lastApprovedDate'),
        'LastApprovedDate',
        undefined,
        false,
        true
      ),
    }),
    [
      allContributors,
      allOwners,
      departmentField,
      getPerformanceByValue,
      st,
      t,
      tagField,
    ]
  );
};

const useGetPolicyTableProps = (
  records: DocumentFields[] | undefined,
  documentAssessmentResults:
    | Array<RecursivePartial<Document_Assessment_Result> | undefined | null>
    | undefined,
  customAttributeSchema?: CustomAttributeFields | null
): UseGetTablePropsOptions<PolicyRegisterFields> => {
  const { t: st } = useTranslation(['common'], { keyPrefix: 'policy' });

  const fields = useGetFieldConfig();
  const labelledFields = useLabelledFields(records, documentAssessmentResults);

  return useMemo(
    () => ({
      tableId: 'policyRegister',
      useRelativeCustomAttributeDates: true,
      data: labelledFields,
      customAttributeSchema: customAttributeSchema,
      entityLabel: st('entity_name'),
      emptyCollectionAction: (
        <Permission permission="insert:document">
          <Button href={addPolicyUrl()}>{st('create_new_button')}</Button>
        </Permission>
      ),
      preferencesStorageKey: 'PolicyRegisterTable-Preferences',
      enableFiltering: true,
      initialColumns: [
        'Title',
        'Parent',
        'DocumentType',
        'allOwners',
        'PerformanceResult',
        'Status',
        'tags',
        'departments',
        'Download',
      ],
      fields,
    }),
    [customAttributeSchema, fields, labelledFields, st]
  );
};

export const useGetCollectionTableProps = (
  records: DocumentFields[] | undefined,
  documentAssessmentResults:
    | Array<RecursivePartial<Document_Assessment_Result> | undefined | null>
    | undefined,
  customAttributeSchema?: CustomAttributeFields | null
): TablePropsWithActions<PolicyRegisterFields> => {
  const props = useGetPolicyTableProps(
    records,
    documentAssessmentResults,
    customAttributeSchema
  );

  return useGetTableProps(props);
};

export const useGetPolicySmartWidgetTableProps = (
  records: DocumentFields[] | undefined,
  documentAssessmentResults:
    | Array<RecursivePartial<Document_Assessment_Result> | undefined | null>
    | undefined,
  customAttributeSchema: CustomAttributeFields | null,
  statefulTableOptions: StatefulTableOptions<PolicyRegisterFields>
): TablePropsWithActions<PolicyRegisterFields> => {
  const props = useGetPolicyTableProps(
    records,
    documentAssessmentResults,
    customAttributeSchema
  );

  return useGetStatelessTableProps<PolicyRegisterFields>({
    ...props,
    ...statefulTableOptions,
    enableFiltering: false,
  });
};
