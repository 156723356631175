
    import './styles.scoped.css';
    export default {
  "series": "awsui_series_1yz9c_1yxg1_185",
  "series--bar": "awsui_series--bar_1yz9c_1yxg1_186",
  "series__rect": "awsui_series__rect_1yz9c_1yxg1_186",
  "exiting": "awsui_exiting_1yz9c_1yxg1_203",
  "awsui-motion-fade-out-0": "awsui_awsui-motion-fade-out-0_1yz9c_1yxg1_1",
  "root": "awsui_root_1yz9c_1yxg1_225",
  "series--highlighted": "awsui_series--highlighted_1yz9c_1yxg1_238",
  "series__bar--highlighted": "awsui_series__bar--highlighted_1yz9c_1yxg1_239",
  "series--dimmed": "awsui_series--dimmed_1yz9c_1yxg1_243",
  "series--threshold": "awsui_series--threshold_1yz9c_1yxg1_247",
  "bar-group": "awsui_bar-group_1yz9c_1yxg1_252"
};
  