import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetObligationsQuery } from 'src/generated/graphql';
import { PageLayout } from 'src/layouts';
import { Permission } from 'src/rbac/Permission';

import { getCounter } from '@/utils/collectionUtils';
import { addObligationUrl } from '@/utils/urls';

import { useGetCollectionTableProps } from './config';
import { ObligationSummary } from './ObligationSummary';

const Page: FC = () => {
  const { t } = useTranslation(['common']);
  const title = t('obligations.register_title');
  const { addNotification } = useNotifications();
  const { data, loading } = useGetObligationsQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const tableProps = useGetCollectionTableProps(
    data?.obligation,
    data?.assessment_result_parent.map((ar) => ar.obligationAssessmentResult),
    data?.form_configuration?.[0]?.customAttributeSchema
  );

  return (
    <PageLayout
      helpTranslationKey="obligations.registerHelp"
      title={title}
      counter={getCounter(tableProps.totalItemsCount, loading)}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button iconName="download" onClick={tableProps.exportToCsv}>
            {t('export')}
          </Button>
          <Permission
            permission="insert:obligation"
            canHaveAccessAsContributor={true}
          >
            <Button variant="primary" href={addObligationUrl()}>
              {`${t('obligations.create_new_button')}`}
            </Button>
          </Permission>
        </SpaceBetween>
      }
    >
      <ObligationSummary
        propertyFilterQuery={tableProps.propertyFilterQuery}
        items={tableProps.allItems}
        onFilterQueryChanged={tableProps.actions.setPropertyFiltering}
        filteringProperties={tableProps.filteringProperties}
      />
      <Table {...tableProps} loading={loading} />
    </PageLayout>
  );
};

export default Page;
