import Button from '@risksmart-app/components/Button';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_COL_WIDTH } from 'src/App.config';
import { Permission } from 'src/rbac/Permission';

import type { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import IndicatorsPopover from '@/components/IndicatorsPopover';
import Link from '@/components/Link';
import ResponsiveRatingBadges from '@/components/ResponsiveRatingBadges';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import type { GetAppetitesGroupedByImpactQuery } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import type { RiskScore } from '@/hooks/useRiskScore';
import { useRiskScoreFormatters } from '@/hooks/useRiskScore';
import { toLocalDate } from '@/utils/dateUtils';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { useGetContributorsFieldConfig } from '@/utils/table/hooks/useGetContributorsFieldConfig';
import { useGetDepartmentFieldConfig } from '@/utils/table/hooks/useGetDepartmentFieldConfig';
import { useGetOwnersFieldConfig } from '@/utils/table/hooks/useGetOwnersFieldConfig';
import type {
  StatefulTableOptions,
  UseGetTablePropsOptions,
} from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetStatelessTableProps } from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { useGetTagFieldConfig } from '@/utils/table/hooks/useGetTagFieldConfig';
import type { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';
import { addRiskUrl, riskDetailsUrl } from '@/utils/urls';

import { useAggregation } from '../../providers/useAggregation';
import { getPerformanceRatingFromPerformanceScore } from '../impacts/ratings/performanceCalculation';
import type { RiskFields, RiskRegisterFields } from './types';
import { useGetLabelledFields } from './useGetLabelledFields';

export const useGetFieldConfig = (
  onEditRating?: ((ratingId: string) => void) | undefined
): TableFields<RiskRegisterFields> => {
  const { riskModel } = useAggregation();
  const ownersField = useGetOwnersFieldConfig<RiskRegisterFields>();
  const contributorsField = useGetContributorsFieldConfig<RiskRegisterFields>();
  const tagField = useGetTagFieldConfig<RiskRegisterFields>();
  const departmentField = useGetDepartmentFieldConfig<RiskRegisterFields>(
    (r) => r.departments
  );
  const posture = useIsFeatureVisibleToOrg('posture');
  const riskScoreFormatters = useRiskScoreFormatters();
  const { getByValue: getImpactByValue } = useRating('impact');
  const { getByValue: getLikelihoodByValue } = useRating('likelihood');
  const { getByValue: getAppetiteByValue } = useRating('risk_appetite');
  const { t } = useTranslation(['common'], { keyPrefix: 'columns' });
  const { t: st } = useTranslation(['common'], { keyPrefix: 'risks.columns' });
  const { t: at } = useTranslation(['common'], {
    keyPrefix: 'appetites.columns',
  });
  const { getByValue: getAppetitePerformanceByValue } = useRating(
    'appetite_performance'
  );
  const { getByValue: getImpactPerformanceByValue } =
    useRating('impact_performance');
  const impactsEnabled = useIsFeatureVisibleToOrg('impacts');
  const { getByValue: getInherentByValue, options: inherentOptions } =
    useRating('risk_uncontrolled');
  let maxInherentRating = Number(
    inherentOptions.sort(
      (a, b) =>
        (!isNaN(Number(b.value)) ? Number(b.value) : 0) -
        (!isNaN(Number(a.value)) ? Number(a.value) : 0)
    )[0].value
  );
  if (isNaN(maxInherentRating)) {
    maxInherentRating = 0;
  }
  const { getByValue: getResidualByValue, options: residualOptions } =
    useRating('risk_controlled');
  let maxResidualRating = Number(
    residualOptions.sort(
      (a, b) =>
        (!isNaN(Number(b.value)) ? Number(b.value) : 0) -
        (!isNaN(Number(a.value)) ? Number(a.value) : 0)
    )[0].value
  );
  if (isNaN(maxResidualRating)) {
    maxResidualRating = 0;
  }

  return useMemo(
    () => ({
      SequentialIdLabel: { header: t('id'), sortingField: 'SequentialId' },
      Title: {
        header: st('risk_name'),
        cell: (item) => (
          <Link variant="secondary" href={riskDetailsUrl(item.Id)}>
            {item.Title}
          </Link>
        ),
        isRowHeader: true,
      },
      ParentTitle: {
        header: st('parent_risk'),
        cell: (item) => item.ParentTitle || 'None',
      },
      TierLabelled: {
        header: st('risk_tier'),
      },
      TreatmentLabelled: {
        header: st('risk_treatment'),
      },
      StatusLabelled: {
        header: st('risk_status'),
      },
      allOwners: ownersField,
      allContributors: contributorsField,
      ...(impactsEnabled || riskModel !== 'default'
        ? {}
        : {
            UncontrolledRatingHistory: {
              header: st('uncontrolled_rating_history'),
              filterOptions: {
                filteringProperties: {
                  operators: [],
                },
              },
              cell: (item) => {
                return (
                  <ResponsiveRatingBadges
                    maxRating={maxInherentRating}
                    invertRating={true}
                    ratings={item.UncontrolledRatingHistory?.filter(
                      (c) => c?.rating !== undefined
                    ).map((c) => ({
                      label: '-',
                      ...getInherentByValue(c.rating!),
                      id: c.id,
                      rating: c.rating,
                      testDate: c.testDate,
                      onClick: onEditRating
                        ? () => onEditRating(c.id)
                        : undefined,
                    }))}
                  ></ResponsiveRatingBadges>
                );
              },
              minWidth: 160,
              exportVal: (item) =>
                item.UncontrolledRatingHistory?.map(
                  (c) =>
                    `${toLocalDate(c.testDate)} ${
                      getInherentByValue(c.rating)?.label
                    }`
                ).join(',') ?? '',
            },
            ControlledRatingHistory: {
              header: st('controlled_rating_history'),
              filterOptions: {
                filteringProperties: {
                  operators: [],
                },
              },
              cell: (item) => {
                return (
                  <ResponsiveRatingBadges
                    maxRating={maxResidualRating}
                    invertRating={true}
                    ratings={item.ControlledRatingHistory?.filter(
                      (c) => c?.rating !== undefined
                    ).map((c) => ({
                      label: '-',
                      ...getResidualByValue(c.rating!),
                      id: c.id,
                      rating: c.rating,
                      testDate: c.testDate,
                      onClick: onEditRating
                        ? () => onEditRating(c.id)
                        : undefined,
                    }))}
                  ></ResponsiveRatingBadges>
                );
              },
              minWidth: 160,
              exportVal: (item) =>
                item.ControlledRatingHistory?.map(
                  (c) =>
                    `${toLocalDate(c.testDate)} ${
                      getResidualByValue(c.rating)?.label
                    }`
                ).join(',') ?? '',
            },
          }),

      ...(impactsEnabled
        ? {}
        : {
            UncontrolledRatingLabelled: {
              header: st('uncontrolled_rating'),
              cell: (item) => {
                return riskScoreFormatters({
                  inherentRating: item.UncontrolledRating,
                }).getInherentRatingBadge();
              },
              sortingField: 'UncontrolledRating',
            },
            ControlledRatingLabelled: {
              header: st('controlled_rating'),
              cell: (item) => {
                return riskScoreFormatters({
                  residualRating: item.ControlledRating,
                }).getResidualRatingBadge();
              },
              sortingField: 'ControlledRating',
            },
            UncontrolledScore: {
              header: st('uncontrolled_score'),
              cell: (item) =>
                riskScoreFormatters({
                  inherentScore: item.UncontrolledScore,
                }).getInherentScoreBadge(),
              filterOptions: {
                filteringProperties: {
                  operators: ['!=', '>', '<', '>=', '<=', '='],
                },
              },
            },
            ControlledScore: {
              header: st('controlled_score'),
              cell: (item) =>
                riskScoreFormatters({
                  residualScore: item.ControlledScore,
                }).getResidualScoreBadge(),
              filterOptions: {
                filteringProperties: {
                  operators: ['!=', '>', '<', '>=', '<=', '='],
                },
              },
            },
          }),

      LinkedControlCount: {
        header: st('linked_controls'),
      },
      ...(impactsEnabled
        ? {
            ImpactPerformanceScore: {
              header: st('impact_performance'),
              cell: (item) => {
                if (item.impactRatings.length === 0) {
                  return '-';
                }
                const impactPerformanceRating = getImpactPerformanceByValue(
                  getPerformanceRatingFromPerformanceScore(
                    item.ImpactPerformanceScore
                  )
                );

                return (
                  <SimpleRatingBadge
                    rating={{
                      ...impactPerformanceRating,
                      label: item.ImpactPerformanceScore?.toString() ?? '-',
                      tooltip: impactPerformanceRating?.label,
                    }}
                  />
                );
              },
            },
          }
        : {
            ControlledLikelihood: {
              header: st('controlled_likelihood'),
              cell: (item) => (
                <SimpleRatingBadge
                  rating={getLikelihoodByValue(item.ControlledLikelihoodValue)}
                />
              ),
              sortingField: 'ControlledLikelihoodValue',
            },
            ControlledLikelihoodValue: {
              header: st('controlled_likelihood_score'),
              cell: (item) =>
                item.ControlledLikelihoodValue ? (
                  <SimpleRatingBadge
                    rating={{
                      ...getLikelihoodByValue(item.ControlledLikelihoodValue),
                      label: item.ControlledLikelihoodValue.toFixed(1),
                    }}
                  />
                ) : (
                  '-'
                ),
            },
            ControlledImpact: {
              header: st('controlled_impact'),
              cell: (item) => (
                <SimpleRatingBadge
                  rating={getImpactByValue(item.ControlledImpactValue)}
                />
              ),
              sortingField: 'ControlledImpactValue',
            },
            ControlledImpactValue: {
              header: st('controlled_impact_score'),
              cell: (item) =>
                item.ControlledImpactValue ? (
                  <SimpleRatingBadge
                    rating={{
                      ...getImpactByValue(item.ControlledImpactValue),
                      label: item.ControlledImpactValue.toFixed(1),
                    }}
                  />
                ) : (
                  '-'
                ),
            },
            UncontrolledImpact: {
              header: st('uncontrolled_impact'),
              cell: (item) => (
                <SimpleRatingBadge
                  rating={getImpactByValue(item.UncontrolledImpactValue)}
                />
              ),
              sortingField: 'UncontrolledImpactValue',
            },
            UncontrolledImpactValue: {
              header: st('uncontrolled_impact_score'),
              cell: (item) =>
                item.UncontrolledImpactValue ? (
                  <SimpleRatingBadge
                    rating={{
                      ...getImpactByValue(item.UncontrolledImpactValue),
                      label: item.UncontrolledImpactValue.toFixed(1),
                    }}
                  />
                ) : (
                  '-'
                ),
            },
            UncontrolledLikelihood: {
              header: st('uncontrolled_likelihood'),
              cell: (item) => (
                <SimpleRatingBadge
                  rating={getLikelihoodByValue(
                    item.UncontrolledLikelihoodValue
                  )}
                />
              ),
              sortingField: 'UncontrolledLikelihoodValue',
            },
            UncontrolledLikelihoodValue: {
              header: st('uncontrolled_likelihood_score'),
              cell: (item) =>
                item.UncontrolledLikelihoodValue ? (
                  <SimpleRatingBadge
                    rating={{
                      ...getLikelihoodByValue(item.UncontrolledLikelihoodValue),
                      label: item.UncontrolledLikelihoodValue.toFixed(1),
                    }}
                  />
                ) : (
                  '-'
                ),
            },
          }),

      ...(!posture
        ? {
            LowerAppetiteLabelled: {
              header: st('lower_appetite'),
              cell: (item) => (
                <SimpleRatingBadge
                  rating={getAppetiteByValue(
                    item.appetites?.[0]?.appetite?.LowerAppetite
                  )}
                />
              ),
            },
          }
        : {}),
      UpperAppetiteLabelled: {
        header: posture ? at('posture') : st('upper_appetite'),
        cell: (item) => (
          <SimpleRatingBadge
            rating={getAppetiteByValue(
              item.appetites?.[0]?.appetite?.UpperAppetite
            )}
          />
        ),
      },
      ...(impactsEnabled
        ? {}
        : {
            AppetitePerformanceLabelled: {
              header: st('appetite_performance'),
              cell: (item) => {
                if (!item.AppetitePerformance) {
                  return '-';
                }

                const performanceItem = getAppetitePerformanceByValue(
                  item.AppetitePerformance
                ) ?? {
                  label: 'Undefined',
                  color: 'light-grey',
                };

                return <SimpleRatingBadge rating={performanceItem} />;
              },
            },
          }),

      tags: tagField,
      departments: departmentField,
      //------------------
      CreatedAtTimestamp: dateColumn(
        t('created_on'),
        'CreatedAtTimestamp',
        undefined,
        false,
        true
      ),
      ModifiedAtTimestamp: dateColumn(
        t('updated_on'),
        'ModifiedAtTimestamp',
        undefined,
        false,
        true
      ),
      Description: {
        header: st('risk_description'),
        cell: (item) => item.Description || '-',
        maxWidth: MAX_COL_WIDTH,
      },

      Id: {
        header: t('guid'),
      },
      CreatedByUser: {
        header: t('created_by_id'),
      },
      ParentRiskId: {
        header: st('associated_risk_id'),
        cell: (item) => item.ParentRiskId || '-',
      },
      UserName: {
        header: t('created_by_username'),
      },
      LatestRatingDate: dateColumn(
        st('latest_rating_date'),
        'LatestRatingDate',
        undefined,
        false,
        true
      ),
      NextTestDate: dateColumn(
        st('next_test_date'),
        'NextTestDate',
        undefined,
        false,
        true
      ),
      NextTestOverdueDate: dateColumn(
        st('nextTestOverdue'),
        'NextTestOverdueDate',
        undefined,
        false,
        true
      ),
      TestFrequency: {
        header: st('test_frequency'),
        cell: (item) => {
          return item.TestFrequency ?? '-';
        },
      },
      LinkedIndicatorCount: {
        header: st('linked_indicators'),
        cell: (item) => (
          <IndicatorsPopover id={item.Id} count={item.LinkedIndicatorCount} />
        ),
      },
    }),
    [
      at,
      contributorsField,
      departmentField,
      getAppetiteByValue,
      getAppetitePerformanceByValue,
      getImpactByValue,
      getImpactPerformanceByValue,
      getLikelihoodByValue,
      getInherentByValue,
      getResidualByValue,
      maxInherentRating,
      maxResidualRating,
      onEditRating,
      riskModel,
      impactsEnabled,
      ownersField,
      posture,
      riskScoreFormatters,
      st,
      t,
      tagField,
    ]
  );
};

const useGetRiskTableProps = (
  records: RiskFields[] | undefined,
  riskScores: RiskScore[] | undefined,
  impactAppetites: GetAppetitesGroupedByImpactQuery['impact'] | undefined,
  customAttributeSchema?: CustomAttributeFields[] | null,
  onEditRating?: ((ratingId: string) => void) | undefined
): UseGetTablePropsOptions<RiskRegisterFields> => {
  const { t: st } = useTranslation(['common'], { keyPrefix: 'risks' });
  const fields = useGetFieldConfig(onEditRating);
  const impactsEnabled = useIsFeatureVisibleToOrg('impacts');
  const labelledFields = useGetLabelledFields(
    records,
    riskScores,
    impactAppetites
  );

  return useMemo<UseGetTablePropsOptions<RiskRegisterFields>>(() => {
    const nonImpactCols: (keyof RiskRegisterFields)[] = impactsEnabled
      ? []
      : ['UncontrolledRatingLabelled', 'ControlledRatingLabelled'];

    return {
      data: labelledFields,
      customAttributeSchema: customAttributeSchema,
      entityLabel: st('entity_name'),
      emptyCollectionAction: (
        <Permission permission="insert:risk">
          <Button href={addRiskUrl()}>{st('create_button')}</Button>
        </Permission>
      ),
      preferencesStorageKey: 'RiskRegisterTable-PreferencesV2',
      tableId: 'riskRegister',
      enableFiltering: true,
      initialColumns: [
        'Title',
        'ParentTitle',
        'TierLabelled',
        'allOwners',
        ...nonImpactCols,
        'LinkedControlCount',
        'tags',
      ],
      fields,
      useRelativeCustomAttributeDates: true,
    };
  }, [customAttributeSchema, fields, impactsEnabled, labelledFields, st]);
};

export const useGetCollectionTableProps = (
  records: RiskFields[] | undefined,
  riskScores: RiskScore[] | undefined,
  impactAppetites: GetAppetitesGroupedByImpactQuery['impact'] | undefined,
  customAttributeSchema?: CustomAttributeFields[] | null,
  onEditRating?: ((ratingId: string) => void) | undefined
): TablePropsWithActions<RiskRegisterFields> => {
  const props = useGetRiskTableProps(
    records,
    riskScores,
    impactAppetites,
    customAttributeSchema,
    onEditRating
  );

  return useGetTableProps(props);
};

export const useGetRiskSmartWidgetTableProps = (
  records: RiskFields[] | undefined,
  riskScores: RiskScore[] | undefined,
  impactAppetites: GetAppetitesGroupedByImpactQuery['impact'] | undefined,
  customAttributeSchema: CustomAttributeFields[] | null,
  statefulTableOptions: StatefulTableOptions<RiskRegisterFields>
): TablePropsWithActions<RiskRegisterFields> => {
  const props = useGetRiskTableProps(
    records,
    riskScores,
    impactAppetites,
    customAttributeSchema
  );

  return useGetStatelessTableProps<RiskRegisterFields>({
    ...props,
    ...statefulTableOptions,
    enableFiltering: false,
  });
};
