import { humanFileSize } from '@risksmart-app/components/File/fileUtils';
import { allowedFileExtensions } from '@risksmart-app/shared/allowedFileExtensions';
import { z } from 'zod';

const regexDate = /\d{4}-\d{2}-\d{2}/;

export const StringDateSchema = z
  .string({ invalid_type_error: 'Required' })
  .regex(regexDate, 'Required')
  .refine((date) => new Date(date) > new Date('1900-01-01'), 'Invalid date');

export const NullableStringDateSchema = StringDateSchema.or(z.string().max(0))
  .transform((value) => (value === '' ? null : value))
  .nullish();

const FileSchema = z.object({
  ContentType: z.string(),
  FileName: z.string(),
  FileSize: z.number(),
  Id: z.string(),
});

export const RelationFilesSchema = z
  .array(
    z.object({
      file: FileSchema.nullish(),
    })
  )
  .optional();

export const NewFileSchema = z.instanceof(File);

export const NewFilesSchema = z.array(NewFileSchema).optional();

export const UserOrGroupSchema = z.object({
  type: z.enum(['user', 'userGroup']),
  value: z.string(),
});

export const UserOptionSchema = z.object(
  {
    type: z.enum(['user']),
    value: z.string(),
  },
  { invalid_type_error: 'Required' }
);

export type UserOption = z.infer<typeof UserOptionSchema>;

export type UserOrGroup = z.infer<typeof UserOrGroupSchema>;

export const validateNewFilesSizeAndExtension = (
  newFiles: z.infer<typeof NewFilesSchema>,
  ctx: z.RefinementCtx
) => {
  const ONE_GB = 1024 * 1024 * 1024;
  for (const file of newFiles || []) {
    const fileParts = file.name.split('.');
    const extension = fileParts[fileParts.length - 1];
    if (!allowedFileExtensions.includes(`.${extension.toLowerCase()}`)) {
      ctx.addIssue({
        message: `${file.name} is an unsupported file type`,
        code: z.ZodIssueCode.custom,
        path: ['newFiles'],
      });
    }
    if (file.size > ONE_GB) {
      ctx.addIssue({
        message: `${file.name} must be less then ${humanFileSize(
          ONE_GB,
          0
        )} in size`,
        code: z.ZodIssueCode.custom,
        path: ['newFiles'],
      });
    }
  }
};

export const InheritedContributorSchema = z.array(
  z.object({
    UserId: z.string().nullish(),
    Id: z.string().nullish(),
    AncestorId: z.string().nullish(),
  })
);

export const UserOrGroupsSchema = z.array(UserOrGroupSchema);

const TagSchema = z.object({
  TagTypeId: z.string(),
});

const DepartmentSchema = z.object({
  DepartmentTypeId: z.string(),
});

const TagsSchema = z.object({
  TagTypeIds: z.array(z.string()),
  tags: z.array(TagSchema),
});

export const FilesSchema = z
  .object({
    files: RelationFilesSchema,
    newFiles: NewFilesSchema,
  })
  .superRefine((values, ctx) => {
    validateNewFilesSizeAndExtension(values.newFiles, ctx);
  });

export type FilesType = z.infer<typeof FilesSchema>;

const DepartmentsSchema = z.object({
  DepartmentTypeIds: z.array(z.string()),
  departments: z.array(DepartmentSchema),
});

export const TagsAndDepartmentsSchema = TagsSchema.and(DepartmentsSchema);

const CustomAttributeDataFieldSchema = z.any().nullish();

export const CustomAttributeDataSchema = z.object({
  CustomAttributeData: CustomAttributeDataFieldSchema,
});
