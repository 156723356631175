import type { CollectionPreferencesProps } from '@cloudscape-design/components/collection-preferences';

import type {
  Dataset,
  RootTablePropsOptions,
  TablePreferences,
  TableRecord,
} from '../types';
import { useAddCustomAttributes } from './useAddCustomAttributes';
import { useCreateColumnDefinitions } from './useCreateColumnDefinitions';
import { useCreateFilterProperties } from './useCreateFilterProperties';
import { useTablePreferences } from './useTablePreferences';

type UsePreprocessTableDataOptions<T extends TableRecord> =
  RootTablePropsOptions<T, Dataset<T>> & {
    currentPage?: number;
    defaultPreferences?: CollectionPreferencesProps.Preferences;
    preferences: TablePreferences<T> | undefined;
  };

export const usePreprocessTableData = <T extends TableRecord>(
  options: UsePreprocessTableDataOptions<T>
) => {
  const { tableFields, tableData } = useAddCustomAttributes({
    data: options.data,
    fields: options.fields,
    customAttributeSchema: options.customAttributeSchema ?? null,
    useRelativeDates: !!options.useRelativeCustomAttributeDates,
  });

  const preferences = useTablePreferences({
    preferences: options.preferences,
    tableFields,
    initialColumns: options.initialColumns,
    defaultPreferences: options.defaultPreferences,
  });

  const columnDefinitions = useCreateColumnDefinitions({
    tableFields,
    columnWidths: preferences.custom?.columnWidths,
  });
  const filteringProperties = useCreateFilterProperties(tableFields);

  return {
    columnDefinitions,
    filteringProperties,
    preferences,
    tableData,
    tableFields,
  };
};
