
    import './styles.scoped.css';
    export default {
  "code-editor-refresh": "awsui_code-editor-refresh_1gl9c_mj672_154",
  "code-editor": "awsui_code-editor_1gl9c_mj672_154",
  "pane": "awsui_pane_1gl9c_mj672_488",
  "pane__close-container": "awsui_pane__close-container_1gl9c_mj672_499",
  "pane__list": "awsui_pane__list_1gl9c_mj672_504",
  "pane__table": "awsui_pane__table_1gl9c_mj672_511",
  "pane__item": "awsui_pane__item_1gl9c_mj672_517",
  "pane__cell": "awsui_pane__cell_1gl9c_mj672_517",
  "pane__item--highlighted": "awsui_pane__item--highlighted_1gl9c_mj672_531",
  "pane__location": "awsui_pane__location_1gl9c_mj672_552",
  "pane__description": "awsui_pane__description_1gl9c_mj672_552",
  "focus-lock": "awsui_focus-lock_1gl9c_mj672_567",
  "editor": "awsui_editor_1gl9c_mj672_614",
  "editor-refresh": "awsui_editor-refresh_1gl9c_mj672_642",
  "status-bar": "awsui_status-bar_1gl9c_mj672_647",
  "status-bar-with-hidden-pane": "awsui_status-bar-with-hidden-pane_1gl9c_mj672_656",
  "status-bar__left": "awsui_status-bar__left_1gl9c_mj672_660",
  "status-bar__right": "awsui_status-bar__right_1gl9c_mj672_667",
  "status-bar__language-mode": "awsui_status-bar__language-mode_1gl9c_mj672_671",
  "status-bar__cursor-position": "awsui_status-bar__cursor-position_1gl9c_mj672_671",
  "status-bar__cog-button": "awsui_status-bar__cog-button_1gl9c_mj672_677",
  "tab-list": "awsui_tab-list_1gl9c_mj672_682",
  "tab-button": "awsui_tab-button_1gl9c_mj672_687",
  "tab-button--refresh": "awsui_tab-button--refresh_1gl9c_mj672_720",
  "tab-button--warnings": "awsui_tab-button--warnings_1gl9c_mj672_723",
  "tab-button--active": "awsui_tab-button--active_1gl9c_mj672_732",
  "tab-button--disabled": "awsui_tab-button--disabled_1gl9c_mj672_748",
  "tab-button--divider": "awsui_tab-button--divider_1gl9c_mj672_781",
  "tab-button--errors": "awsui_tab-button--errors_1gl9c_mj672_788",
  "count": "awsui_count_1gl9c_mj672_797",
  "text": "awsui_text_1gl9c_mj672_800",
  "loading-screen": "awsui_loading-screen_1gl9c_mj672_822",
  "error-screen": "awsui_error-screen_1gl9c_mj672_823"
};
  