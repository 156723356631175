import type { FC, ReactNode } from 'react';
import { useCallback, useState } from 'react';

import type {
  FieldContent,
  FormFieldHelpContent,
  HelpContent,
} from './HelpContext';
import { HelpContext } from './HelpContext';

export const HelpProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [formFieldHelpContent, setFieldHelpContent] =
    useState<FormFieldHelpContent>({});
  const [showFieldHelpInPopover, setShowFieldHelpInPopover] =
    useState<boolean>(false);
  const [translationKey, setTranslationKey] = useState<string>('');
  const [contentId, setContentId] = useState<string | null>(null);
  const [summaryHelpContent, setSummaryHelpContent] =
    useState<HelpContent | null>(null);
  const addFieldHelp = useCallback(
    (fieldId: string, helpContent: FieldContent) =>
      setFieldHelpContent((fields) => {
        if (fields[fieldId] != helpContent) {
          return { ...fields, [fieldId]: helpContent };
        }

        return fields;
      }),
    []
  );
  const removeFieldHelp = useCallback((fieldId: string) => {
    setFieldHelpContent((fields) => {
      if (fields[fieldId]) {
        delete fields[fieldId];

        return { ...fields };
      }

      return fields;
    });
  }, []);
  const hasHelpContent =
    (summaryHelpContent &&
      !!summaryHelpContent.length &&
      (summaryHelpContent[0].title !== '' ||
        summaryHelpContent[0].content !== '')) ||
    !!Object.keys(formFieldHelpContent).length;

  return (
    <HelpContext.Provider
      value={{
        addFieldHelp,
        removeFieldHelp,
        formFieldHelpContent,
        summaryHelpContent,
        setSummaryHelpContent,
        setContentId,
        contentId,
        hasHelpContent,
        translationKey,
        setTranslationKey,
        showFieldHelpInPopover,
        setShowFieldHelpInPopover,
      }}
    >
      {children}
    </HelpContext.Provider>
  );
};
