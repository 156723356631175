import i18n from '@risksmart-app/components/providers/i18n';
import { useTranslation } from 'react-i18next';

import {
  Indicator_Type_Enum,
  Parent_Type_Enum,
  useGetIndicatorByIdLazyQuery,
  useGetIndicatorResultsByIndicatorIdLazyQuery,
} from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { toLocalDate } from '@/utils/dateUtils';
import { getFriendlyId } from '@/utils/friendlyId';
import { getContributorValue } from '@/utils/pdf/contributorValue';
import { getDepartmentsValue } from '@/utils/pdf/departmentValue';
import { createDocument } from '@/utils/pdf/document';
import { download } from '@/utils/pdf/downloader';
import { createField } from '@/utils/pdf/field';
import { createHeading, createSubHeading } from '@/utils/pdf/headings';
import { getOwnerValue } from '@/utils/pdf/ownerValue';
import { createTable, tableHeaders } from '@/utils/pdf/table';
import { optionalTableSection } from '@/utils/pdf/tableSection';
import { getTagsValue } from '@/utils/pdf/tagsValue';
import { twoColumns } from '@/utils/pdf/twoColumns';
import useCustomAttributeDataForExport from '@/utils/pdf/useCustomAttributeDataForExport';

import {
  conformanceIndicatorRating,
  getConformanceTrendRating,
} from '../calculateConformanceRating';

const useExporter = (
  indicatorId: string
): [() => void, { loading: boolean }] => {
  const [getCustomAttribute, customAttributesLoading] =
    useCustomAttributeDataForExport(Parent_Type_Enum.Indicator);
  const { getLabel: trendGetByValue } = useRating(
    'indicator_conformance_trend'
  );
  const { getLabel: statusGetByValue } = useRating(
    'indicator_conformance_status'
  );
  const { getLabel: getIndicatorTypeLabel } = useRating('indicator_type');
  const { getLabel: getTestFreqLabel } = useRating('frequency');
  const { t: indicatorFields } = useTranslation(['common'], {
    keyPrefix: 'indicators.fields',
  });
  const { t } = useTranslation(['common']);
  const { t: indicatorResultsColumns } = useTranslation(['common'], {
    keyPrefix: 'indicator_results.columns',
  });
  const { t: fields } = useTranslation(['common'], { keyPrefix: 'fields' });
  const [getIndicator, getIndicatorResult] = useGetIndicatorByIdLazyQuery({
    variables: {
      id: indicatorId,
    },
    fetchPolicy: 'no-cache',
  });
  const [getIndicatorResults, getIndicatorResultsResult] =
    useGetIndicatorResultsByIndicatorIdLazyQuery({
      variables: {
        indicatorId,
      },
      fetchPolicy: 'no-cache',
    });

  const loading =
    getIndicatorResult.loading ||
    getIndicatorResultsResult.loading ||
    customAttributesLoading;

  const exportFunc = async () => {
    const indicatorPromise = getIndicator();
    const indicatorResultsPromise = getIndicatorResults();

    const { data: indicatorData } = await indicatorPromise;
    const { data: indicatorResultsData } = await indicatorResultsPromise;

    const indicator = indicatorData?.indicator?.[0];
    const indicatorResults = indicatorResultsData?.indicator_result;

    if (!indicator || !indicatorResults) {
      return;
    }
    const indicatorResultsWithPrevious = indicatorResults.map((result, i) => ({
      ...result,
      previous: indicatorResults[i - 1],
    }));
    const indicatorResultsTableData = indicatorResultsWithPrevious.map((au) => [
      toLocalDate(au.ResultDate),
      au.Description ?? '',
      au.modifiedBy?.FriendlyName ?? '',
      au.TargetValueNum ?? au.TargetValueTxt ?? '',

      statusGetByValue(conformanceIndicatorRating(indicator, au)),

      trendGetByValue(
        getConformanceTrendRating(
          indicator,
          au.previous ? [au, au.previous] : [au]
        )
      ),
    ]);

    const detailsTextFields =
      indicator.Type === Indicator_Type_Enum.Text
        ? [
            createField(
              indicatorFields('target_text_value'),
              indicator.TargetValueTxt
            ),
          ]
        : [];
    const detailsNumberFields =
      indicator.Type === Indicator_Type_Enum.Number
        ? [
            createField(indicatorFields('unit'), indicator.Unit),
            createField(
              indicatorFields('lower_tolerance_num'),
              indicator.LowerToleranceNum
            ),
            createField(
              indicatorFields('upper_tolerance_num'),
              indicator.UpperToleranceNum
            ),
          ]
        : [];
    const detailFields = [
      createField(indicatorFields('title'), indicator.Title),
      createField(
        indicatorFields('test_frequency'),
        getTestFreqLabel(indicator.schedule?.Frequency)
      ),
      createField(
        indicatorFields('type'),
        getIndicatorTypeLabel(indicator.Type)
      ),
      ...detailsNumberFields,
      ...detailsTextFields,
      createField(indicatorFields('description'), indicator.Description ?? ''),
      createField(fields('Owner'), getOwnerValue(indicator)),
      createField(fields('Contributor'), getContributorValue(indicator)),
      createField(fields('Tags'), getTagsValue(indicator)),
      createField(fields('Departments'), getDepartmentsValue(indicator)),
      ...(await getCustomAttribute(indicator)),
    ];
    const title = `${indicator.Title} (${getFriendlyId(
      Parent_Type_Enum.Indicator,
      indicator.SequentialId
    )})`;
    const docDefinition = createDocument(title, [
      createHeading(title),
      createSubHeading(t('details')),
      twoColumns(detailFields),
      optionalTableSection(
        i18n.format(t('indicator_result_other'), 'capitalize'),
        createTable({
          widths: ['*', '*', 70, 50, 70, 70],
          body: [
            tableHeaders([
              indicatorResultsColumns('date_time'),
              indicatorResultsColumns('description'),
              indicatorResultsColumns('modified_by'),
              indicatorResultsColumns('result'),
              indicatorResultsColumns('conformance'),
              indicatorResultsColumns('conformance_trend'),
            ]),
            ...indicatorResultsTableData,
          ],
        })
      ),
    ]);
    download(docDefinition);
  };

  return [exportFunc, { loading }];
};

export default useExporter;
