import _ from 'lodash';

export const mapChanges = (
  from: Record<string, unknown>,
  to: Record<string, unknown>
) => {
  return compare(from, to);
};

const compare = (a: Record<string, unknown>, b: Record<string, unknown>) => {
  const changes: Record<string, { from: unknown; to: unknown }> = {};
  if (!_.isObject(a) && !_.isObject(b)) {
    return changes;
  }
  const keys = _.union(_.keys(a), _.keys(b));
  for (const key of keys) {
    if (a?.[key] !== b?.[key]) {
      changes[key] = { from: a?.[key], to: b?.[key] };
      const subChanges = compare(
        a?.[key] as Record<string, unknown>,
        b?.[key] as Record<string, unknown>
      );
      for (const subKey in subChanges) {
        changes[key + '.' + subKey] = subChanges[subKey];
      }
    }
  }

  return changes;
};
