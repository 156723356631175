import type { Filter } from '@/components/CustomisableRibbon/Filter';

export const defaultRibbonFilters: Filter[] = [
  {
    id: crypto.randomUUID(),
    title: 'Tier 1',
    itemFilterQuery: {
      tokens: [
        {
          operator: '=',
          propertyKey: 'TierLabelled',
          value: 'Tier 1',
        },
      ],
      operation: 'and',
    },
    itemOrder: 0,
  },
  {
    id: crypto.randomUUID(),
    title: 'Tier 2',
    itemFilterQuery: {
      tokens: [
        {
          operator: '=',
          propertyKey: 'TierLabelled',
          value: 'Tier 2',
        },
      ],
      operation: 'and',
    },
    itemOrder: 0,
  },
  {
    id: crypto.randomUUID(),
    title: 'Tier 3',
    itemFilterQuery: {
      tokens: [
        {
          operator: '=',
          propertyKey: 'TierLabelled',
          value: 'Tier 3',
        },
      ],
      operation: 'and',
    },
    itemOrder: 0,
  },
  {
    id: crypto.randomUUID(),
    title: 'All risks',
    itemFilterQuery: {
      tokens: [],
      operation: 'and',
    },
    itemOrder: 0,
  },
];
