
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_39ths_1r73m_205",
  "file-loading-overlay": "awsui_file-loading-overlay_39ths_1r73m_240",
  "file-loading-overlay-single-row": "awsui_file-loading-overlay-single-row_39ths_1r73m_245",
  "file-option-name": "awsui_file-option-name_39ths_1r73m_249",
  "file-option-size": "awsui_file-option-size_39ths_1r73m_250",
  "file-option-last-modified": "awsui_file-option-last-modified_39ths_1r73m_251",
  "file-option": "awsui_file-option_39ths_1r73m_249",
  "file-option-thumbnail": "awsui_file-option-thumbnail_39ths_1r73m_264",
  "file-option-thumbnail-image": "awsui_file-option-thumbnail-image_39ths_1r73m_268",
  "file-option-metadata": "awsui_file-option-metadata_39ths_1r73m_281",
  "with-image": "awsui_with-image_39ths_1r73m_284",
  "single-row-loading": "awsui_single-row-loading_39ths_1r73m_287",
  "token": "awsui_token_39ths_1r73m_291",
  "token-grid": "awsui_token-grid_39ths_1r73m_298",
  "token-contains-image": "awsui_token-contains-image_39ths_1r73m_307",
  "token-box": "awsui_token-box_39ths_1r73m_311",
  "horizontal": "awsui_horizontal_39ths_1r73m_330",
  "error": "awsui_error_39ths_1r73m_338",
  "dismiss-button": "awsui_dismiss-button_39ths_1r73m_342",
  "warning": "awsui_warning_39ths_1r73m_348",
  "read-only": "awsui_read-only_39ths_1r73m_358",
  "loading": "awsui_loading_39ths_1r73m_371"
};
  