import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_COL_WIDTH } from 'src/App.config';

import type { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { useRating } from '@/hooks/use-rating';
import { EMPTY_CELL } from '@/utils/collectionUtils';
import { useGetContributorsFieldConfig } from '@/utils/table/hooks/useGetContributorsFieldConfig';
import { useGetDepartmentFieldConfig } from '@/utils/table/hooks/useGetDepartmentFieldConfig';
import { useGetOwnersFieldConfig } from '@/utils/table/hooks/useGetOwnersFieldConfig';
import type {
  StatefulTableOptions,
  UseGetTablePropsOptions,
} from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetStatelessTableProps } from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { useGetTagFieldConfig } from '@/utils/table/hooks/useGetTagFieldConfig';
import { useLinkArrayField } from '@/utils/table/hooks/useLinkArrayField';
import type { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';
import { yesNoCell } from '@/utils/table/utils/yesNoCell';

import type { IssueFlatField, IssueRegisterFields } from './types';
import { useLabelledFields } from './useLabelledFields';

export const useGetFieldConfig = (): TableFields<IssueRegisterFields> => {
  const allOwners = useGetOwnersFieldConfig<IssueRegisterFields>();
  const allContributors = useGetContributorsFieldConfig<IssueRegisterFields>();
  const { t } = useTranslation(['common'], { keyPrefix: 'columns' });
  const { t: st } = useTranslation(['common'], { keyPrefix: 'issues.columns' });
  const { t: ft } = useTranslation(['common'], {
    keyPrefix: 'issues.footerLabels',
  });
  const { t: gt } = useTranslation(['common']);
  const status = useRating('issue_assessment_status');
  const severity = useRating('severity');
  const departmentField = useGetDepartmentFieldConfig<IssueRegisterFields>(
    (r) => r.departments
  );
  const assessmentDepartmentsField =
    useGetDepartmentFieldConfig<IssueRegisterFields>(
      (r) => r.AssessmentDepartments ?? []
    );
  const tagField = useGetTagFieldConfig<IssueRegisterFields>();
  const associations = useLinkArrayField<IssueRegisterFields>(
    t('associations'),
    (r) => r.ParentTitle ?? []
  );

  return useMemo(
    () => ({
      SequentialIdLabel: { header: t('id'), sortingField: 'SequentialId' },
      Title: {
        header: st('title'),
        cell: (item) => (
          <Link variant="secondary" href={`/issues/${item.Id}`}>
            {item.Title}
          </Link>
        ),
        isRowHeader: true,
      },
      allOwners,
      allContributors,
      IssueTypeLabelled: {
        header: st('type'),
      },
      ParentTitle: associations,
      SeverityLabelled: {
        header: st('severity'),
        cell: (item) => {
          const rating = severity.getByValue(item.Severity);

          return rating ? (
            <SimpleRatingBadge rating={rating}>
              {item.SeverityLabelled}
            </SimpleRatingBadge>
          ) : (
            EMPTY_CELL
          );
        },
      },
      OpenActions: {
        header: st('open_actions'),
      },
      StatusLabelled: {
        header: st('status'),
        cell: (item) => {
          const rating = status.getByValue(item.Status);

          return rating ? <SimpleRatingBadge rating={rating} /> : EMPTY_CELL;
        },
      },
      RaisedAtTimestamp: dateColumn(
        st('raised'),
        'RaisedAtTimestamp',
        undefined,
        false,
        true
      ),
      CreatedAtTimestamp: dateColumn(
        st('createdOn'),
        'CreatedAtTimestamp',
        undefined,
        false,
        true
      ),
      DateIdentified: dateColumn(
        st('date_identified'),
        'DateIdentified',
        undefined,
        false,
        true
      ),
      TargetCloseDate: dateColumn(
        st('target_close_date'),
        'TargetCloseDate',
        undefined,
        false,
        true
      ),
      tags: tagField,
      departments: departmentField,
      ActualCloseDate: dateColumn(
        st('actual_close_date'),
        'ActualCloseDate',
        undefined,
        false,
        true
      ),
      AssessmentDepartments: {
        ...assessmentDepartmentsField,
        header: st('assessment_departments'),
      },
      CertifiedIndividual: { header: st('certified_individual') },
      DateOccurred: dateColumn(
        st('date_occurred'),
        'DateOccurred',
        undefined,
        false,
        true
      ),
      Details: { header: st('details') },
      ImpactsCustomer: {
        header: st('impacts_customer'),
        cell: yesNoCell('ImpactsCustomer'),
      },
      IsExternalIssue: {
        header: st('is_external_issue'),
        cell: yesNoCell('IsExternalIssue'),
      },
      IssueCausedBySystemIssue: {
        header: st('caused_by_system_issue'),
        cell: yesNoCell('IssueCausedBySystemIssue'),
      },
      SystemResponsible: {
        header: st('system_responsible'),
      },
      IssueCausedByThirdParty: {
        header: st('issue_caused_by_third_party'),
        cell: yesNoCell('IssueCausedByThirdParty'),
      },
      ThirdPartyResponsible: { header: st('third_party_responsible') },
      PolicyBreach: {
        header: st('policy_breach'),
        cell: yesNoCell('PolicyBreach'),
      },
      PoliciesBreached: { header: st('policies_breached') },
      PolicyOwner: { header: st('policy_owner') },
      PolicyOwnerCommentary: {
        header: st('policy_owner_commentary'),
        maxWidth: MAX_COL_WIDTH,
      },
      Rationale: { header: st('rationale') },
      Reportable: { header: st('reportable'), cell: yesNoCell('Reportable') },
      RegulatoryBreach: {
        header: st('regulatory_breach'),
        cell: yesNoCell('RegulatoryBreach'),
      },
      RegulationsBreached: {
        header: st('regulations_breeched'),
      },
      ModifiedAtTimestamp: dateColumn(
        t('updated_on'),
        'ModifiedAtTimestamp',
        undefined,
        false,
        true
      ),
      ModifiedByUser: { header: t('updated_by_id') },
      ModifiedByUserName: { header: st('modified_by_username') },
      CreatedByUserName: { header: st('created_by_username') },
      AssessmentCreatedBy: { header: st('assessment_created_by_username') },
      AssessmentModifiedBy: {
        header: st('assessment_modified_by_username'),
      },
      ParentId: { header: st('parent_id') },
      Hours: {
        filterOptions: {
          filteringProperties: {
            operators: ['!=', '>', '<', '>=', '<='],
          },
        },
        header: st('hours'),
        footerVal: (items) =>
          items.reduce((previous, current) => previous + current.Hours, 0),
        footerLabel: ft('hours'),
      },
      Cost: {
        filterOptions: {
          filteringProperties: {
            operators: ['!=', '>', '<', '>=', '<='],
          },
        },
        header: st('cost'),
        footerVal: (items) =>
          items.reduce((previous, current) => previous + current.Cost, 0),
        footerLabel: ft('cost'),
      },
      CustomersImpacted: {
        filterOptions: {
          filteringProperties: {
            operators: ['!=', '>', '<', '>=', '<='],
          },
        },
        header: st('customers_impacted'),
        footerVal: (items) =>
          items.reduce(
            (previous, current) => previous + current.CustomersImpacted,
            0
          ),
        footerLabel: ft('customers_impacted'),
      },
      TimeToResolve: {
        header: st('time_to_resolve'),
        cell: (item) =>
          _.isNil(item.TimeToResolve)
            ? EMPTY_CELL
            : gt('units.day', { count: item.TimeToResolve }),
      },
      TimeToReport: {
        header: st('time_to_report'),
        cell: (item) =>
          _.isNil(item.TimeToReport)
            ? EMPTY_CELL
            : gt('units.day', { count: item.TimeToReport }),
      },
      TimeToIdentify: {
        header: st('time_to_identify'),
        cell: (item) =>
          _.isNil(item.TimeToIdentify)
            ? EMPTY_CELL
            : gt('units.day', { count: item.TimeToIdentify }),
      },
      TimeSinceCreated: {
        header: st('time_since_created'),
        cell: (item) =>
          _.isNil(item.TimeSinceCreated)
            ? EMPTY_CELL
            : gt('units.day', { count: item.TimeSinceCreated }),
      },
      Id: {
        header: t('guid'),
      },
    }),
    [
      allContributors,
      allOwners,
      assessmentDepartmentsField,
      associations,
      departmentField,
      ft,
      gt,
      severity,
      st,
      status,
      t,
      tagField,
    ]
  );
};

const useGetIssueTableProps = (
  records: IssueFlatField[] | undefined,
  customAttributeSchema?: CustomAttributeFields[] | null
): UseGetTablePropsOptions<IssueRegisterFields> => {
  const { t } = useTranslation(['common']);
  const data = useLabelledFields(records);
  const fields = useGetFieldConfig();

  return useMemo(
    () => ({
      tableId: 'issueRegister',
      data,
      customAttributeSchema,
      entityLabel: t('issue_one'),
      emptyCollectionAction: <></>,
      preferencesStorageKey: 'IssuesRegisterTable-PreferencesV1',
      enableFiltering: true,
      initialColumns: [
        'Title',
        'allOwners',
        'ParentTitle',
        'IssueTypeLabelled',
        'SeverityLabelled',
        'OpenActions',
        'StatusLabelled',
        'RaisedAtTimestamp',
        'TargetCloseDate',
        'tags',
      ],
      fields,
      useRelativeCustomAttributeDates: true,
    }),
    [customAttributeSchema, data, fields, t]
  );
};

export const useGetRegisterTableProps = (
  records: IssueFlatField[] | undefined,
  customAttributeSchema?: CustomAttributeFields[] | null,
  loading?: boolean
): TablePropsWithActions<IssueRegisterFields> => {
  const props = useGetIssueTableProps(records, customAttributeSchema);

  return useGetTableProps({ ...props, enableFiltering: !loading });
};

export const useGetIssueSmartWidgetTableProps = (
  records: IssueFlatField[] | undefined,
  customAttributeSchema: CustomAttributeFields[] | null,
  statefulTableOptions: StatefulTableOptions<IssueRegisterFields>
): TablePropsWithActions<IssueRegisterFields> => {
  const props = useGetIssueTableProps(records, customAttributeSchema);

  return useGetStatelessTableProps<IssueRegisterFields>({
    ...props,
    ...statefulTableOptions,
    enableFiltering: false,
  });
};
