import type { AxiosInstance } from 'axios';
import axios, { isAxiosError } from 'axios';

import type { Parent_Type_Enum } from '../generated/graphql';
import { useAxios } from '../hooks/useAxios';
import { getEnv } from '../utils/environment';

type PresignedUrlResponse = {
  fileName: string;
  key: string;
  signedUrl: string;
};

type SaveFileType = {
  fileName: string;
  fileId: string;
  fileSize: number;
  mimeType: string;
};

type SaveFileRequest = {
  parentIds: string[];
  parentType: string;
  files: SaveFileType[];
};

type Response = { fileIds: string[] };

const getPresignedUrls = async (
  axios: AxiosInstance,
  parentIds: string[],
  parentType: Parent_Type_Enum,
  files: File[]
) => {
  const { data } = await axios.post<PresignedUrlResponse[]>(
    `/files/presigned`,
    {
      parentType,
      parentIds,
      fileNames: files.map((f) => f.name),
    }
  );

  return data;
};

const uploadToS3 = async (presignedUrl: string, file: File) => {
  const axiosInstance = axios.create({
    baseURL: getEnv('REACT_APP_REST_API_URL'),
  });

  return await axiosInstance.put(presignedUrl, file, {
    headers: {
      'Content-Type': file.type,
      Authorization: undefined,
    },
  });
};

const saveRelationFileData = async (
  axios: AxiosInstance,
  request: SaveFileRequest
) => {
  const { data } = await axios.post<Response>(`/files/save`, request);

  return data;
};

export const useFileUpload = () => {
  const axios = useAxios();

  return async (
    parentType: Parent_Type_Enum,
    parentIds: string[],
    files: Array<File & { meta?: { [key: string]: string } }>
  ): Promise<Response> => {
    try {
      const presignedUrls = await getPresignedUrls(
        axios,
        parentIds,
        parentType,
        files
      );

      const putPromises = presignedUrls.map(async (url, index) => {
        const file = files[index];
        await uploadToS3(url.signedUrl, file);

        return file;
      });
      const successfulFiles = await Promise.all(putPromises);

      const saveResult = await saveRelationFileData(axios, {
        parentIds,
        parentType,
        files: successfulFiles.map((f) => ({
          fileName: f.name,
          fileId: presignedUrls.find((x) => x.fileName === f.name)?.key || '',
          fileSize: f.size,
          mimeType: f.type,
          meta: f.meta,
        })),
      });

      return { fileIds: saveResult.fileIds };
    } catch (error) {
      if (isAxiosError(error)) {
        error.message = 'Something went wrong';
      }
      throw error;
    }
  };
};
