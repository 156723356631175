import FormField from '@cloudscape-design/components/form-field';
import Input from '@cloudscape-design/components/input';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Reorder } from 'framer-motion';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import Button from '../../../Button';
import { DraggableItem } from '../../../DragableItem/DraggableItem';
import type {
  ExtendedControlProps,
  FieldOption,
} from '../../context/useFormBuilderContext';
import { useFormBuilderContext } from '../../context/useFormBuilderContext';
import { CustomisableControl } from './CustomisableControl';

const AddOptionControlUnwrapped: FC<ExtendedControlProps> = ({
  uischema,
  schema,
  errors,
  handleChange,
  enabled,
  data,
  id,
  path,
  config,
  visible,
}) => {
  const { isFormDirty } = useFormBuilderContext();
  const { t } = useTranslation(['common'], {
    keyPrefix: 'formBuilder.formQuestion',
  });

  const appliedUiSchemaOptions = {
    ...config,
    ...uischema.options,
  };

  const setOptions = (options: { value: string; generatedId: string }[]) => {
    handleChange(path, options);
  };

  const onAddOptionField = () => {
    const newOptions = [...data, { value: '', generatedId: uuidv4() }];
    setOptions(newOptions);
  };

  const updateOption = (generatedId: string, value: number | string | null) => {
    const options = [...data];
    const option = options.find((o) => o.generatedId === generatedId);

    if (option) {
      option.value = `${value}`;
      setOptions(options);
    }
  };

  const deleteOption = (generatedId: string) => {
    const options = [...data];
    const optionIndex = options.findIndex((o) => o.generatedId === generatedId);

    if (optionIndex > -1) {
      options.splice(optionIndex, 1);
      setOptions(options);
    }
  };

  const optionHasError = (option: FieldOption) => {
    if (isFormDirty) {
      return option.value ? '' : t('optionRequiredErrorMessage');
    }

    return '';
  };

  return (
    <>
      {visible ? (
        <CustomisableControl
          uischema={uischema}
          errors={errors}
          schema={schema}
        >
          <div className={'pb-6'}>
            <Reorder.Group
              axis="y"
              className="flex flex-col gap-y-4 p-0 m-0"
              values={data || []}
              onReorder={setOptions}
            >
              {(data || []).map((fieldOption: FieldOption) => (
                <DraggableItem
                  value={fieldOption}
                  key={fieldOption.generatedId}
                  deleteOption={() => deleteOption(fieldOption.generatedId)}
                >
                  <FormField
                    label={''}
                    stretch={true}
                    errorText={optionHasError(fieldOption)}
                  >
                    <Input
                      {...{ className: 'grow' }}
                      type={'text'}
                      inputMode={'text'}
                      value={fieldOption.value || ''}
                      onChange={(event) => {
                        updateOption(
                          fieldOption.generatedId,
                          event.detail.value
                        );
                      }}
                      name={id}
                      disabled={!enabled}
                      autoFocus={appliedUiSchemaOptions.focus}
                      placeholder={appliedUiSchemaOptions?.placeholder}
                    />
                  </FormField>
                </DraggableItem>
              ))}
            </Reorder.Group>

            <div className={'mt-4'}>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  onAddOptionField();
                }}
              >
                {t('addOptionButtonLabel')}
              </Button>
            </div>
          </div>
        </CustomisableControl>
      ) : null}
    </>
  );
};

export const AddOptionControl = withJsonFormsControlProps(
  // For more info on why this is ignored, see `Known Issues` in `@risksmart-app/docs/form-builder.md`
  // @ts-ignore
  AddOptionControlUnwrapped
);
