import type {
  BoardProps,
  ItemsPaletteProps,
} from '@cloudscape-design/board-components';
import i18n from '@risksmart-app/components/providers/i18n';
import type { ForwardRefRenderFunction } from 'react';
import { forwardRef } from 'react';
import type { HasPermission } from 'src/rbac/Permission';
import { v4 as uuidv4 } from 'uuid';

import { Cost_Type_Enum } from '@/generated/graphql';
import type { IsFeatureVisibleToOrg } from '@/utils/featureFlags';

import type {
  StoredWidgetDefinition,
  StoredWidgetPlacement,
  WidgetDefinition,
  WidgetRef,
  WidgetTranslationObject,
} from './types';
import { UniversalWidget } from './UniversalWidget/UniversalWidget';
import { OpenIssuesOverTime } from './widgets/OpenIssuesOverTime';
import { RichTextWidget } from './widgets/RichTextWidget/RichTextWidget';
import { ControlledRiskHeatmap } from './widgets/RiskHeatmap/ControlledRiskHeatmap';
import { UncontrolledRiskHeatmap } from './widgets/RiskHeatmap/UncontrolledRiskHeatmap';

export const getWidgetTranslations = (widget: WidgetDefinition) => {
  return i18n.t(widget.translationKeyPrefix, {
    returnObjects: true,
  }) as WidgetTranslationObject;
};

const defaultWidgetOptions = {
  table: {
    centerAlignHeader: true,
    disableContentPaddings: true,
    definition: {
      defaultRowSpan: 5,
      defaultColumnSpan: 4,
    },
  },
  chart: {
    definition: {
      defaultRowSpan: 4,
      defaultColumnSpan: 2,
    },
  },
  statistic: {
    centerAlignHeader: true,
    definition: {
      defaultRowSpan: 2,
      defaultColumnSpan: 1,
    },
  },
  richText: {
    definition: {
      defaultRowSpan: 1,
      defaultColumnSpan: 5,
    },
  },
} satisfies Record<string, Partial<WidgetDefinition>>;

const hasPermissionToViewWidget = (
  widget: WidgetDefinition,
  hasPermission: HasPermission,
  isFeatureVisibleToOrg: IsFeatureVisibleToOrg
) => {
  return !widget.hide?.(hasPermission, isFeatureVisibleToOrg);
};

export const getPaletteItems = (
  items: BoardProps.Item<StoredWidgetDefinition>[],
  hasPermission: HasPermission,
  isFeatureVisibleToOrg: IsFeatureVisibleToOrg
): ItemsPaletteProps.Item<StoredWidgetDefinition>[] => {
  return Object.keys(widgets)
    .filter(
      (widgetType: string) =>
        !items.find((i) => i.data.widgetType === widgetType) ||
        widgets[widgetType as WidgetId].multiple
    )
    .filter((widgetType) =>
      hasPermissionToViewWidget(
        widgets[widgetType as WidgetId],
        hasPermission,
        isFeatureVisibleToOrg
      )
    )
    .map((widgetType) => ({
      id: uuidv4(),
      data: { widgetType, ...widgets[widgetType as WidgetId] },
      definition: widgets[widgetType as WidgetId].definition,
    }));
};

type CreateWidgetDefinition = Omit<WidgetDefinition, 'content'> & {
  content?: ForwardRefRenderFunction<WidgetRef>;
};

const createWidget = (
  definition: CreateWidgetDefinition
): WidgetDefinition => ({
  ...definition,
  content: forwardRef<WidgetRef>(definition.content ?? UniversalWidget),
  multiple: true,
});

export const widgets: Record<string, WidgetDefinition> = {
  gigawidget: createWidget({
    ...defaultWidgetOptions.chart,
    translationKeyPrefix: 'dashboard.widgets.gigawidget',
    content: UniversalWidget,
    multiple: true,
  }),
  richText: createWidget({
    ...defaultWidgetOptions.richText,
    translationKeyPrefix: 'dashboard.widgets.richText',
    content: RichTextWidget,
    multiple: true,
  }),
  openRiskAcceptances: createWidget({
    ...defaultWidgetOptions.statistic,
    translationKeyPrefix: 'dashboard.widgets.openRiskAcceptances',
    settings: {
      dataSource: 'acceptance',
      filtering: {
        tokens: [
          {
            propertyKey: 'StatusLabelled',
            operator: '=',
            value: 'Open',
          },
        ],
        operation: 'and',
      },
      ignoreDashboardDateFilter: true,
      chartType: 'kpi',
      aggregationType: 'count',
    },
  }),
  indicatorsDeteriorating: createWidget({
    ...defaultWidgetOptions.statistic,
    translationKeyPrefix: 'dashboard.widgets.indicatorsDeteriorating',
    settings: {
      dataSource: 'indicator',
      filtering: {
        tokens: [
          {
            propertyKey: 'ConformanceTrend',
            operator: '=',
            value: 'deteriorating',
          },
        ],
        operation: 'and',
      },
      chartType: 'kpi',
      aggregationType: 'count',
    },
  }),
  indicatorsImproving: createWidget({
    ...defaultWidgetOptions.statistic,
    translationKeyPrefix: 'dashboard.widgets.indicatorsImproving',
    settings: {
      dataSource: 'indicator',
      filtering: {
        tokens: [
          {
            propertyKey: 'ConformanceTrend',
            operator: '=',
            value: 'improving',
          },
        ],
        operation: 'and',
      },
      chartType: 'kpi',
      aggregationType: 'count',
    },
  }),
  indicatorsStable: createWidget({
    ...defaultWidgetOptions.statistic,
    translationKeyPrefix: 'dashboard.widgets.indicatorsStable',
    settings: {
      dataSource: 'indicator',
      filtering: {
        tokens: [
          {
            propertyKey: 'ConformanceLabelled',
            operator: '=',
            value: 'Within',
          },
        ],
        operation: 'and',
      },
      chartType: 'kpi',
      aggregationType: 'count',
    },
  }),
  indicatorsOutOfTolerance: createWidget({
    ...defaultWidgetOptions.statistic,
    translationKeyPrefix: 'dashboard.widgets.indicatorsOutOfTolerance',
    settings: {
      dataSource: 'indicator',
      filtering: {
        tokens: [
          {
            propertyKey: 'ConformanceLabelled',
            operator: '=',
            value: 'Outside',
          },
        ],
        operation: 'and',
      },
      chartType: 'kpi',
      aggregationType: 'count',
    },
  }),
  issuesRaisedInPeriod: createWidget({
    ...defaultWidgetOptions.statistic,
    translationKeyPrefix: 'dashboard.widgets.issuesRaisedInPeriod',
    settings: {
      dataSource: 'issue',
      customTitle: true,
      chartType: 'kpi',
      aggregationType: 'count',
    },
  }),
  sumOfFinancialConsequencesByType: createWidget({
    ...defaultWidgetOptions.chart,
    translationKeyPrefix: 'dashboard.widgets.sumOfFinancialConsequencesByType',
    settings: {
      dataSource: 'consequence',
      chartType: 'pie',
      filtering: {
        tokens: [
          {
            propertyKey: 'CostTypeLabelled',
            operator: ':',
            value: `${Cost_Type_Enum.Financial}`,
          },
        ],
        operation: 'and',
      },
      categoryGetter: 'type',
      aggregationType: 'sum',
      aggregationField: 'CostValue',
      customTitle: true,
    },
    hide(_: HasPermission, isFeatureVisibleToOrg) {
      return !!isFeatureVisibleToOrg('disable-consequences');
    },
  }),
  sumOfHoursConsequencesByType: createWidget({
    ...defaultWidgetOptions.chart,
    translationKeyPrefix: 'dashboard.widgets.sumOfHoursConsequencesByType',
    settings: {
      dataSource: 'consequence',
      chartType: 'pie',
      filtering: {
        tokens: [
          {
            propertyKey: 'CostTypeLabelled',
            operator: '=',
            value: 'Hours',
          },
        ],
        operation: 'and',
      },
      categoryGetter: 'type',
      aggregationType: 'sum',
      aggregationField: 'CostValue',
      customTitle: true,
    },
    hide(_: HasPermission, isFeatureVisibleToOrg) {
      return !!isFeatureVisibleToOrg('disable-consequences');
    },
  }),
  sumOfCustomersConsequencesByType: createWidget({
    ...defaultWidgetOptions.chart,
    translationKeyPrefix: 'dashboard.widgets.sumOfCustomersConsequencesByType',
    settings: {
      dataSource: 'consequence',
      chartType: 'pie',
      filtering: {
        tokens: [
          {
            propertyKey: 'CostTypeLabelled',
            operator: '=',
            value: 'Customers impacted',
          },
        ],
        operation: 'and',
      },
      categoryGetter: 'type',
      aggregationType: 'sum',
      aggregationField: 'CostValue',
      customTitle: true,
    },
    hide(_: HasPermission, isFeatureVisibleToOrg) {
      return !!isFeatureVisibleToOrg('disable-consequences');
    },
  }),
  sumOfNumberConsequencesByType: createWidget({
    ...defaultWidgetOptions.chart,
    translationKeyPrefix: 'dashboard.widgets.sumOfNumberConsequencesByType',
    settings: {
      dataSource: 'consequence',
      chartType: 'pie',
      filtering: {
        tokens: [
          {
            propertyKey: 'CostTypeLabelled',
            operator: '=',
            value: 'Number',
          },
        ],
        operation: 'and',
      },
      categoryGetter: 'type',
      aggregationType: 'sum',
      aggregationField: 'CostValue',
      customTitle: true,
    },
    hide(_: HasPermission, isFeatureVisibleToOrg) {
      return !!isFeatureVisibleToOrg('disable-consequences');
    },
  }),
  actionsByStatus: createWidget({
    ...defaultWidgetOptions.chart,
    translationKeyPrefix: 'dashboard.widgets.actionsByStatus',
    settings: {
      dataSource: 'action',
      chartType: 'donut',
      categoryGetter: 'status',
      customTitle: true,
    },
  }),
  risksByUncontrolledRiskRating: createWidget({
    ...defaultWidgetOptions.chart,
    translationKeyPrefix: 'dashboard.widgets.risksByUncontrolledRiskRating',
    settings: {
      dataSource: 'risk',
      chartType: 'donut',
      categoryGetter: 'uncontrolledRating',
      customTitle: true,
    },
  }),
  risksByControlledRiskRating: createWidget({
    ...defaultWidgetOptions.chart,
    translationKeyPrefix: 'dashboard.widgets.risksByControlledRiskRating',
    settings: {
      dataSource: 'risk',
      chartType: 'donut',
      categoryGetter: 'controlledRating',
      customTitle: true,
    },
  }),
  controlEffectivenessByDepartment: createWidget({
    ...defaultWidgetOptions.chart,
    translationKeyPrefix: 'dashboard.widgets.controlEffectivenessByDepartment',
    settings: {
      dataSource: 'control',
      chartType: 'stacked-bar',
      categoryGetter: 'departments',
      subCategoryGetter: 'effectiveness',
      customTitle: true,
    },
  }),
  controlledRiskRatingByDepartment: createWidget({
    ...defaultWidgetOptions.chart,
    translationKeyPrefix: 'dashboard.widgets.controlledRiskRatingByDepartment',
    settings: {
      dataSource: 'risk',
      chartType: 'stacked-bar',
      categoryGetter: 'departments',
      subCategoryGetter: 'controlledRating',
      customTitle: true,
    },
  }),
  uncontrolledRiskRatingByDepartment: createWidget({
    ...defaultWidgetOptions.chart,
    translationKeyPrefix:
      'dashboard.widgets.uncontrolledRiskRatingByDepartment',
    settings: {
      dataSource: 'risk',
      chartType: 'stacked-bar',
      categoryGetter: 'departments',
      subCategoryGetter: 'uncontrolledRating',
      customTitle: true,
    },
  }),
  issueRaisedSeverityByMonth: createWidget({
    ...defaultWidgetOptions.chart,
    translationKeyPrefix: 'dashboard.widgets.issueRaisedSeverityByMonth',
    settings: {
      dataSource: 'issue',
      chartType: 'stacked-bar',
      categoryGetter: 'createdDate',
      precision: 'month',
      subCategoryGetter: 'severity',
      customTitle: true,
    },
  }),
  documentReviewsDueByMonth: createWidget({
    ...defaultWidgetOptions.chart,
    translationKeyPrefix: 'dashboard.widgets.documentReviewsDueByMonth',
    settings: {
      dataSource: 'document',
      chartType: 'bar',
      categoryGetter: 'nextReviewDue',
      precision: 'month',
      customTitle: true,
    },
  }),
  issuesTable: createWidget({
    ...defaultWidgetOptions.table,
    translationKeyPrefix: 'dashboard.widgets.issuesTable',
    settings: {
      dataSource: 'issue',
      chartType: 'table',
    },
  }),
  overdueActions: createWidget({
    ...defaultWidgetOptions.statistic,
    translationKeyPrefix: 'dashboard.widgets.overdueActions',
    settings: {
      dataSource: 'action',
      customTitle: true,
      filtering: {
        tokens: [
          {
            propertyKey: 'Overdue',
            operator: '=',
            value: 'true',
          },
        ],
        operation: 'and',
      },
      ignoreDashboardDateFilter: true,
      chartType: 'kpi',
      aggregationType: 'count',
    },
  }),
  openIssuesOverTime: createWidget({
    ...defaultWidgetOptions.chart,
    translationKeyPrefix: 'dashboard.widgets.openIssuesOverTime',
    content: OpenIssuesOverTime,
    hide: (hasPermission) => {
      return !hasPermission('read:issue_assessment_audit');
    },
  }),
  oldestOpenIssue: createWidget({
    ...defaultWidgetOptions.statistic,
    translationKeyPrefix: 'dashboard.widgets.oldestOpenIssue',
    settings: {
      dataSource: 'issue',
      customTitle: true,
      filtering: {
        tokens: [
          {
            propertyKey: 'StatusLabelled',
            operator: '=',
            value: 'Open',
          },
        ],
        operation: 'and',
      },
      sorting: {
        sortingColumn: {
          sortingField: 'CreatedAtTimestamp',
        },
        isDescending: false,
      },
      ignoreDashboardDateFilter: true,
      chartType: 'kpi',
      aggregationType: 'max',
      aggregationField: 'TimeSinceCreated',
      customUnit: true,
      unit: 'Days',
    },
  }),
  averageTimeToResolve: createWidget({
    ...defaultWidgetOptions.statistic,
    translationKeyPrefix: 'dashboard.widgets.averageTimeToResolve',
    settings: {
      dataSource: 'issue',
      customTitle: true,
      chartType: 'kpi',
      aggregationType: 'mean',
      aggregationField: 'TimeToResolve',
      customUnit: true,
      unit: 'Days',
    },
  }),
  openIssues: createWidget({
    ...defaultWidgetOptions.statistic,
    translationKeyPrefix: 'dashboard.widgets.openIssues',
    settings: {
      dataSource: 'issue',
      customTitle: true,
      filtering: {
        tokens: [
          {
            propertyKey: 'StatusLabelled',
            operator: '=',
            value: 'Open',
          },
        ],
        operation: 'and',
      },
      ignoreDashboardDateFilter: true,
      chartType: 'kpi',
      aggregationType: 'count',
    },
  }),
  issueCauses: createWidget({
    ...defaultWidgetOptions.chart,
    translationKeyPrefix: 'dashboard.widgets.issueCauses',
    settings: {
      dataSource: 'cause',
      chartType: 'donut',
      categoryGetter: 'Title',
      customTitle: true,
    },
    hide(_: HasPermission, isFeatureVisibleToOrg) {
      return !!isFeatureVisibleToOrg('disable-causes');
    },
  }),
  issueByRaisedMonth: createWidget({
    ...defaultWidgetOptions.chart,
    translationKeyPrefix: 'dashboard.widgets.issueByRaisedMonth',
    settings: {
      dataSource: 'issue',
      chartType: 'bar',
      categoryGetter: 'createdDate',
      precision: 'month',
      customTitle: true,
    },
  }),
  openIssuesByType: createWidget({
    ...defaultWidgetOptions.chart,
    translationKeyPrefix: 'dashboard.widgets.openIssuesByType',
    settings: {
      dataSource: 'issue',
      chartType: 'donut',
      filtering: {
        tokens: [
          {
            propertyKey: 'StatusLabelled',
            operator: '=',
            value: 'Open',
          },
        ],
        operation: 'and',
      },
      categoryGetter: 'type',
      customTitle: true,
    },
  }),
  openActionsByPriority: createWidget({
    ...defaultWidgetOptions.chart,
    translationKeyPrefix: 'dashboard.widgets.openActionsByPriority',
    settings: {
      dataSource: 'action',
      chartType: 'donut',
      filtering: {
        tokens: [
          {
            propertyKey: 'StatusLabelled',
            operator: '=',
            value: 'Open',
          },
        ],
        operation: 'and',
      },
      categoryGetter: 'priority',
      customTitle: true,
    },
  }),
  controlEffectiveness: createWidget({
    ...defaultWidgetOptions.chart,
    translationKeyPrefix: 'dashboard.widgets.controlEffectiveness',
    settings: {
      dataSource: 'control',
      chartType: 'donut',
      categoryGetter: 'effectiveness',
      customTitle: true,
    },
  }),
  openIssueSeverity: createWidget({
    ...defaultWidgetOptions.chart,
    translationKeyPrefix: 'dashboard.widgets.openIssueSeverity',
    settings: {
      dataSource: 'issue',
      chartType: 'donut',
      filtering: {
        tokens: [
          {
            propertyKey: 'StatusLabelled',
            operator: '=',
            value: 'Open',
          },
        ],
        operation: 'and',
      },
      categoryGetter: 'severity',
      customTitle: true,
    },
  }),
  controlledRiskHeatMap: createWidget({
    ...defaultWidgetOptions.chart,
    translationKeyPrefix: 'dashboard.widgets.controlledRiskHeatMap',
    content: ControlledRiskHeatmap,
  }),
  uncontrolledRiskHeatMap: createWidget({
    ...defaultWidgetOptions.chart,
    translationKeyPrefix: 'dashboard.widgets.uncontrolledRiskHeatMap',
    content: UncontrolledRiskHeatmap,
  }),
  averageTimeToIdentify: createWidget({
    ...defaultWidgetOptions.statistic,
    translationKeyPrefix: 'dashboard.widgets.averageTimeToIdentify',
    settings: {
      dataSource: 'issue',
      customTitle: true,
      chartType: 'kpi',
      aggregationType: 'mean',
      aggregationField: 'TimeToIdentify',
      customUnit: true,
      unit: 'Days',
    },
  }),
  averageTimeToReport: createWidget({
    ...defaultWidgetOptions.statistic,
    translationKeyPrefix: 'dashboard.widgets.averageTimeToReport',
    settings: {
      dataSource: 'issue',
      customTitle: true,
      chartType: 'kpi',
      aggregationType: 'mean',
      aggregationField: 'TimeToReport',
      customUnit: true,
      unit: 'Days',
    },
  }),
  controlTestsDueByMonth: createWidget({
    ...defaultWidgetOptions.chart,
    translationKeyPrefix: 'dashboard.widgets.controlTestsDueByMonth',
    settings: {
      dataSource: 'control',
      chartType: 'bar',
      categoryGetter: 'nextTestDate',
      precision: 'month',
      customTitle: true,
    },
  }),
};

const defaultLayout: StoredWidgetPlacement[] = [
  {
    id: '508e4c08-ed22-4fd3-aa1e-8656bfb4aaaf',
    widgetType: 'overdueActions',
    rowSpan: 2,
    columnSpan: 1,
  },
  {
    id: '8f19cfb5-4163-4717-a3e2-f4f4343bac10',
    widgetType: 'openIssues',
    rowSpan: 2,
    columnSpan: 1,
  },
  {
    id: '008618da-d2a8-4118-aa05-c2989184b48d',
    widgetType: 'oldestOpenIssue',
    rowSpan: 2,
    columnSpan: 1,
  },
  {
    id: '2255ca2e-d840-44dd-b9d1-8f28ac72b0aa',
    widgetType: 'averageTimeToResolve',
    rowSpan: 2,
    columnSpan: 1,
  },
  {
    id: '574f7ba5-9f6d-4a1a-8045-d1dcb5806b5e',
    widgetType: 'openIssuesOverTime',
    rowSpan: 4,
    columnSpan: 2,
  },
  {
    id: '2d73997a-5a9f-47b8-a5ff-29cb4bf08a1d',
    widgetType: 'controlEffectiveness',
    rowSpan: 4,
    columnSpan: 2,
  },
  {
    id: '7c9472df-90b4-4a9c-9629-a2e3953f8545',
    widgetType: 'openIssueSeverity',
    rowSpan: 4,
    columnSpan: 2,
  },
  {
    id: 'ea7214d8-a5bb-42a5-9bb5-eb4d43dcc1d4',
    widgetType: 'controlledRiskHeatMap',
    rowSpan: 4,
    columnSpan: 2,
  },
];

export const filterWidgetsByUserPermissions = <
  T extends { widgetType: WidgetId },
>(
  widgetsToFilter: T[],
  hasPermission: HasPermission,
  isFeatureVisibleToOrg: IsFeatureVisibleToOrg
): T[] =>
  widgetsToFilter.filter(({ widgetType }) =>
    widgets[widgetType]
      ? hasPermissionToViewWidget(
          widgets[widgetType],
          hasPermission,
          isFeatureVisibleToOrg
        )
      : false
  );

export const getDefaultLayout = (
  hasPermission: HasPermission,
  isFeatureVisibleToOrg: IsFeatureVisibleToOrg
) =>
  filterWidgetsByUserPermissions(
    defaultLayout,
    hasPermission,
    isFeatureVisibleToOrg
  );

export type WidgetId = keyof typeof widgets;
