import { JsonForms } from '@jsonforms/react';

import { useFormBuilderContext } from './context/useFormBuilderContext';
import { FormBuilderAddSection } from './FormBuilderAddSection';
import { FormQuestionModal } from './FormQuestionModal';
import rendererRegistry from './renderers/registry';

export const FormBuilder = () => {
  const {
    formData,
    schema,
    uiSchema,
    isFormCustomisable,
    setFormData,
    setUISchema,
  } = useFormBuilderContext();

  return (
    <>
      <div key={'form-builder'} className={'flex flex-col gap-y-4 mb-8'}>
        {/*
          Avoid spreading data into Json Forms as it is a
          known anti-pattern that causes infinite render loops
          https://jsonforms.io/faq/#how-can-i-minimize-re-rendering
        */}
        <JsonForms
          readonly={true}
          data={formData}
          schema={schema}
          uischema={uiSchema}
          renderers={rendererRegistry}
          onChange={({ data }) => setFormData(data)}
          validationMode={'NoValidation'}
        />

        {isFormCustomisable ? (
          <>
            <FormBuilderAddSection
              schema={schema}
              uiSchema={uiSchema}
              setUISchema={setUISchema}
            />

            <FormQuestionModal />
          </>
        ) : null}
      </div>
    </>
  );
};
