import type { FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router';
import type { ObligationFormFieldData } from 'src/pages/compliance/obligations/forms/obligationSchema';
import { defaultValues } from 'src/pages/compliance/obligations/forms/obligationSchema';
import { useHasPermission } from 'src/rbac/useHasPermission';

import { ownerAndContributorIds } from '@/components/Form';
import type { InsertObligationMutationVariables } from '@/generated/graphql';
import {
  namedOperations,
  Obligation_Type_Enum,
  useInsertObligationMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';
import { obligationDetailsUrl } from '@/utils/urls';

import ObligationDetailsForm from '../../../forms/ObligationDetailsForm';

const Tab: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const typeParam = searchParams.get('type') || '';
  const canCreateObligation = useHasPermission('insert:obligation', null, true);
  const [mutate] = useInsertObligationMutation({
    update: (cache) => evictField(cache, 'obligation'),
    refetchQueries: [
      namedOperations.Query.getObligationById,
      namedOperations.Query.getObligationsByType,
    ],
  });

  const onSave = async ({
    ancestorContributors: _1,
    departments: _2,
    tags: _3,
    Contributors,
    Owners,
    ...data
  }: ObligationFormFieldData) => {
    const saveData: InsertObligationMutationVariables = {
      object: {
        ...data,
        TagTypeIds: data.TagTypeIds,
        DepartmentTypeIds: data.DepartmentTypeIds,
        ...ownerAndContributorIds({ Contributors, Owners }),
        CustomAttributeData: data.CustomAttributeData,
      },
    };
    const { data: resultData } = await mutate({ variables: saveData });
    if (resultData?.insertChildObligation?.Id) {
      navigate(obligationDetailsUrl(resultData.insertChildObligation.Id), {
        replace: true,
      });
    }
  };
  const onDismiss = (saved?: boolean) => {
    if (!saved) {
      navigate(-1);
    }
  };

  return (
    <>
      <ObligationDetailsForm
        initialType={
          Object.values<string>(Obligation_Type_Enum).includes(typeParam)
            ? (typeParam as Obligation_Type_Enum)
            : undefined
        }
        defaultValues={defaultValues}
        onSave={onSave}
        onDismiss={onDismiss}
        readOnly={!canCreateObligation}
      />
    </>
  );
};

export default Tab;
