import i18n from '@risksmart-app/components/providers/i18n';
import { useGetAssessmentSmartWidgetTableProps } from 'src/pages/assessments/config';
import type { AssessmentRegisterFields } from 'src/pages/assessments/types';

import {
  GetAssessmentsDocument,
  Parent_Type_Enum,
} from '@/generated/graphql.typed';
import { assessmentRegisterUrl } from '@/utils/urls';

import {
  dateRangeFilter,
  departmentsFilter,
  tagsFilter,
} from '../../Gigawidget/util/filterHelpers';
import { createDataSource } from '../createDataSource';
import {
  dashboardDateClickthroughFilter,
  dateClickthroughFilter,
  tagAndDepartmentCategoryGetters,
} from '../dataSourceHelpers';

export default createDataSource({
  hasAccess: () => true,
  documentNode: GetAssessmentsDocument,
  defaultVariables: () => ({ where: {} }),
  parentTypes: [Parent_Type_Enum.Assessment],
  useTablePropsHook: (data, options) =>
    useGetAssessmentSmartWidgetTableProps(
      'rating',
      data?.assessment,
      data?.form_configuration[0]?.customAttributeSchema ?? null,
      options
    ),
  entityNamePlural: 'assessment_other',
  entityNameSingular: 'assessment_one',
  fields: 'assessments.fields',
  dashboardFilterConfig: {
    tagsFilter: (tags) => ({ where: { tags: tagsFilter(tags) } }),
    departmentsFilter: (departments) => ({
      where: { departments: departmentsFilter(departments) },
    }),
    dateFilter: (dateRange, precision) => ({
      where: { CreatedAtTimestamp: dateRangeFilter(dateRange, precision) },
    }),
    dateClickthroughFilter:
      dashboardDateClickthroughFilter('CreatedAtTimestamp'),
  },
  clickThroughUrl: (filters) => assessmentRegisterUrl(filters),
  categoryGetters: [
    ...tagAndDepartmentCategoryGetters<AssessmentRegisterFields>(),
    {
      id: 'owner',
      name: () => i18n.t('assessments.columns.Owner'),
      categoryGetter: (item) =>
        item.allOwners.map((owner) => ({ key: owner.id, label: owner.label })),
      clickthroughFilter: (category) => [
        {
          propertyKey: 'allOwners',
          operator: '=',
          value: category.key,
        },
      ],
    },
    {
      id: 'completionDate',
      name: () => i18n.t('assessments.columns.CompletionDate'),
      categoryGetter: (data) =>
        data.ActualCompletionDate ? new Date(data.ActualCompletionDate) : null,
      date: true,
      dashboardDateFilterOverride: (dateRange, precision) => ({
        where: { ActualCompletionDate: dateRangeFilter(dateRange, precision) },
      }),
      clickthroughFilter: dateClickthroughFilter('ActualCompletionDate'),
    },
    {
      id: 'startDate',
      name: () => i18n.t('assessments.columns.StartDate'),
      categoryGetter: (data) =>
        data.StartDate ? new Date(data.StartDate) : null,
      date: true,
      dashboardDateFilterOverride: (dateRange, precision) => ({
        where: { StartDate: dateRangeFilter(dateRange, precision) },
      }),
      clickthroughFilter: dateClickthroughFilter('StartDate'),
    },
    {
      id: 'createdDate',
      name: () => i18n.t('columns.created_on'),
      categoryGetter: (data) => new Date(data.CreatedAtTimestamp),
      date: true,
      dashboardDateFilterOverride: (dateRange, precision) => ({
        where: { CreatedAtTimestamp: dateRangeFilter(dateRange, precision) },
      }),
      clickthroughFilter: dateClickthroughFilter('CreatedAtTimestamp'),
    },
  ],
});
