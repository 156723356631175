import '@cloudscape-design/global-styles/index.css';

import { NotificationProvider } from '@risksmart-app/components/Notifications/NotificationProvider';
import { AxiosProvider } from '@risksmart-app/components/providers/AxiosProvider';
import { getEnv } from '@risksmart-app/components/utils/environment';
import type { FC } from 'react';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Outlet } from 'react-router';

import { AuthProvider } from './AuthProvider';
import { HelpProvider } from './components/HelpPanel/HelpProvider';
import UpdatePrompt from './components/UpdatePrompt';
import ApolloGraphqlProvider from './data/ApolloGraphqlProvider';
import { AggregationProvider } from './providers/AggregationProvider';
import { AnalyticsProvider, AnalyticsUserProvider } from './segment';

function GetSegmentId() {
  return getEnv('REACT_APP_SEGMENT_KEY');
}

const Providers: FC = () => {
  return (
    <AnalyticsProvider writeKey={GetSegmentId()}>
      <HelmetProvider>
        <AuthProvider>
          <AnalyticsUserProvider>
            <NotificationProvider>
              <ApolloGraphqlProvider>
                <AxiosProvider>
                  <HelpProvider>
                    <AggregationProvider>
                      <Outlet />
                      <UpdatePrompt />
                    </AggregationProvider>
                  </HelpProvider>
                </AxiosProvider>
              </ApolloGraphqlProvider>
            </NotificationProvider>
          </AnalyticsUserProvider>
        </AuthProvider>
      </HelmetProvider>
    </AnalyticsProvider>
  );
};

export default Providers;
