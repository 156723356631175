import Input from '@cloudscape-design/components/input';
import type { InputProps } from '@cloudscape-design/components/input/interfaces';
import { withJsonFormsControlProps } from '@jsonforms/react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type {
  ExtendedControlProps,
  FieldType,
} from '../../context/useFormBuilderContext';
import { CustomisableControl } from './CustomisableControl';

type TextFieldTypes = Extract<FieldType, 'text' | 'number' | 'url'>;

const TextControlUnwrapped: FC<ExtendedControlProps> = ({
  schema,
  uischema,
  errors,
  handleChange,
  enabled,
  data,
  id,
  path,
  config,
}) => {
  const fieldType: TextFieldTypes = uischema?.options?.fieldType || 'text';

  const { t } = useTranslation(['common'], {
    keyPrefix: 'formBuilder.placeholders',
  });

  const appliedUiSchemaOptions = {
    ...config,
    ...uischema.options,
  };

  const defaultPlaceholders = {
    text: t('text'),
    number: t('number'),
    url: t('url'),
  };

  const inputMode: Record<TextFieldTypes, InputProps.InputMode> = {
    text: 'text',
    number: 'numeric',
    url: 'url',
  };

  return (
    <CustomisableControl uischema={uischema} errors={errors} schema={schema}>
      <Input
        {...{ className: 'grow' }}
        type={fieldType || 'text'}
        inputMode={inputMode[fieldType] || 'text'}
        value={data || ''}
        onChange={(event) => {
          handleChange(path, event.detail.value);
        }}
        name={id}
        disabled={!enabled}
        autoFocus={appliedUiSchemaOptions.focus}
        placeholder={
          appliedUiSchemaOptions?.placeholder ||
          (fieldType && defaultPlaceholders[fieldType]) ||
          defaultPlaceholders['text']
        }
      />
      {schema.allowAttachments && <></>}
    </CustomisableControl>
  );
};

export const TextControl = withJsonFormsControlProps(
  // For more info on why this is ignored, see `Known Issues` in `@risksmart-app/docs/form-builder.md`
  // @ts-ignore
  TextControlUnwrapped
);
