import acceptance from './acceptance';
import action from './action';
import assessment from './assessment';
import attestations from './attestations';
import cause from './cause';
import changeRequests from './changeRequests';
import consequence from './consequence';
import control from './control';
import controlTest from './controlTest';
import document from './document';
import indicator from './indicator';
import internalAudits from './internalAudits';
import issue from './issue';
import obligation from './obligation';
import risk from './risk';
import thirdParty from './thirdParty';

export const dataSources = {
  risk,
  issue,
  action,
  control,
  controlTest,
  document,
  cause,
  assessment,
  consequence,
  obligation,
  indicator,
  acceptance,
  thirdParty,
  internalAudits,
  changeRequests,
  attestations,
};
