import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Header } from '../../theme/risksmart-themed/components';
import {
  FormBuilderAction,
  useFormBuilderContext,
} from './context/useFormBuilderContext';
import { defaultQuestionData } from './form-configs/question';

type FormBuilderAddSectionProps = {
  parentId: string;
};

export const FormBuilderAddQuestion: FC<FormBuilderAddSectionProps> = ({
  parentId,
}) => {
  const {
    uiSchema,
    setFormQuestionModalAction,
    setIsEditingQuestion,
    setCurrentElementUISchema,
    setQuestionData,
    setParentId,
  } = useFormBuilderContext();
  const { t } = useTranslation(['common'], {
    keyPrefix: 'formBuilder.formQuestion',
  });

  return (
    <>
      <div
        className={
          'flex w-max p-3 transition hover:cursor-pointer hover:bg-grey150 rounded-md'
        }
        onClick={() => {
          setFormQuestionModalAction(FormBuilderAction.Add);
          setParentId(parentId);
          setCurrentElementUISchema(uiSchema);
          setQuestionData(defaultQuestionData);
          setIsEditingQuestion(true);
        }}
      >
        <Header variant={'h3'}>{t('addQuestionButtonLabel')}</Header>
      </div>
    </>
  );
};
